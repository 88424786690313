import React from 'react'
import {  Row, Col } from "reactstrap";
// Redux
import {  useSelector, useDispatch } from "react-redux";
import { lostDevice } from 'store/auth/actions';
// Components
import { PopUpModal } from 'Components/Global'
import ReactCodeInput from 'react-verification-code-input';
// Functions
import { translate } from 'helpers/helpers';
import Microsoft from 'assets/images/microsoft-authenticator-app.png'
import Google from 'assets/images/google-2fa.png'


const TwoFactorLoginModal = ({ modal, togglemodal, values, getCode }) => {

    const error = useSelector(state => state.Login.error)
    const success = useSelector(state => state.Login.success)
    const dispatch = useDispatch()
    const handleValidSubmit = event => { getCode(event) }

      return(
        <PopUpModal
          isOpen={modal}
          togglemodal={togglemodal}
          title={translate('login.two-factor-auth')}
          success={success}
          error={error}
          >
            <p className="text-center">{translate('user.2fa-login-text')}</p>
            <ReactCodeInput onComplete={handleValidSubmit}/>
            <Row className="mt-4 justify-content-center">
              <p className="text-muted underline" onClick={() => dispatch(lostDevice(values))}>{translate('lost-device')}</p>
            </Row>
        </PopUpModal>
     )
  }



  export default TwoFactorLoginModal

