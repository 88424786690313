import React from "react";
import { withRouter } from "react-router-dom";
// Redux
import { forgotPassword, clearState } from "store/auth/actions";
import {  useSelector, useDispatch } from "react-redux";
// Components
import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import TextInputField from "Components/Form/TextInputField";
import { Alerts } from "Components/Global";
import Image from 'assets/images/error-img.png'

// Functions
import { translate } from 'helpers/helpers';
import { FORGOT_PASSWORD_SUCCESS } from "store/auth/actionTypes";

const ForgetPasswordForm = ({ profilePage, email, history }) => {

  const error = useSelector(state => state.Login.error)
  const success = useSelector(state => state.Login.success)
  const dispatch = useDispatch()

  const handleValidSubmit = (event, values) => {
      dispatch(clearState())
      dispatch(forgotPassword(values)).then(({ type }) => {
        if (type === FORGOT_PASSWORD_SUCCESS) {
          history.replace('/login');
        }
      })
    }

    return (
      <Card className="ecosystem-dashboard-box-shadow col-lg-10">
        <div className="mt-3">
          <Alerts success={success} error={error} />
        </div>
          {!profilePage &&
          <Row>
            <Col className="col-12">
              <div className="p-4">
                <h5 className="text-muted"> {translate("login.forgot")} </h5>
              </div>
              <img src={Image} className="img-fluid" alt="forgot password"/>
            </Col>
          </Row> }
        <CardBody>
          <div className="p-2">
            <AvForm className="form-horizontal pt-4" onValidSubmit={handleValidSubmit} >
              <FormGroup>
                <TextInputField
                  name="email"
                  id="email"
                  value={email ? email : ''}
                  placeholder={"form.enter-email"}
                  errorMessage={"form.enter-email"}
                  type={"text"}
                  required={true}
                  label={profilePage ? '' : 'login.forgot-text'}
                />
              </FormGroup>
              <Row className="form-group">
                <Col sm={12}>
                  <Button
                    color="success"
                    className=" w-md waves-effect waves-light col-lg-12"
                    type="submit"
                  >
                    {translate("login.reset")}
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </CardBody>
      </Card>
    );
  }


export default withRouter(ForgetPasswordForm)
