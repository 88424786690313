import React, { useEffect } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import Layout from "./AdminPanel";
import NonAuthLayout from "./AdminPanel/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import { I18nProvider } from './helpers/Translation/index';
import { getMe } from 'store/global/actions'
import { LoaderOnPage } from 'Components/Global'

import Cookies from 'universal-cookie'
const cookies = new Cookies()
var token = cookies.get('token')


const App = () => {

	const me = useSelector(state => state.Global.me)
	const locale = useSelector(state => state.Global.locale)
	const dispatch = useDispatch();

	useEffect(() => {
		if(token) dispatch(getMe())
		else if(
			(window.location.pathname !== '/login') && (window.location.href.indexOf('forget-password') < 0) &&  (window.location.pathname !== '/gdpr')) {
			window.location = "/login"
		}
	},[dispatch])

		return (
				<Router>
					{me !== null ?
						<I18nProvider locale={locale}>
							<Switch>
								{publicRoutes.map((route, idx) => (
									<AppRoute
										path={route.path}
										layout={NonAuthLayout}
										component={route.component}
										key={idx}
										isAuthProtected={false}
									/>
								))}
								{token && me && authProtectedRoutes.map((route, idx) => (
									<AppRoute
										path={route.path}
										layout={Layout}
										component={route.component}
										key={idx}
										isAuthProtected={true}
									/>
								 ))}
							</Switch>
						</I18nProvider>
					: <LoaderOnPage />}
				</Router>
			);
}

export default App;
