
import React from "react";
// Redux
import { withRouter } from "react-router-dom";
import {  useSelector, useDispatch } from "react-redux";
import { changePassword , clearState} from "store/auth/actions";
// Components
import { Row, Col, Card, CardBody, FormGroup, Button, CardHeader } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import TextInputField from "Components/Form/TextInputField";
import ecosystemCardHeader from "assets/styles/EcosystemCardHeader";
import { Alerts } from 'Components/Global'
// Helpers
import { translate } from 'helpers/helpers';
import { PASSWORD_REGEXP } from "Constants";


const ChangePasswordForm = ({ match }) => {

  const error = useSelector(state => state.Login.error)
  const success = useSelector(state => state.Login.success)
  const dispatch = useDispatch()

  const handleValidSubmit = (event, values) => {
    const token = match.params.token
    dispatch(clearState())
    dispatch(changePassword(values, token))
  }

    return (
        <Card className="ecosystem-dashboard-box-shadow col-lg-8">
            <CardHeader style={ecosystemCardHeader}>
              <h5 >{translate('login.change-password-title')}</h5>
              <p className="text-muted">{translate('login.change-password-text')}</p>
            </CardHeader>
            <CardBody className="pt-0 mt-5">
              <Alerts error={error} success={success} />
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={handleValidSubmit}
                      >
                        <FormGroup>
                          <TextInputField
                              name="password"
                              id="password"
                              value=""
                              placeholder={'form.enter-password'}
                              errorMessage={'form.enter-password'}
                              type="password"
                              required={true}
                              label={'form.password'}
                              min={10}
                              validate={{ pattern: { value: PASSWORD_REGEXP } }}
                          />
                        </FormGroup>
                        <FormGroup>
                        <TextInputField
                              name="password_confirmation"
                              id="password_confirmation"
                              value=""
                              placeholder={'form.confirm-password'}
                              errorMessage={'form.confirm-password-error'}
                              type="password"
                              required={true}
                              validate={{ match:{ value:'password'} }}
                              label={'form.confirm-password'}
                          />
                        </FormGroup>
                        <Row className="form-group">
                          <Col lg={12} className="text-right">
                            <Button
                              color="success"
                              className=" col-lg-6"
                              type="submit"
                            >
                              {translate('login.new-password')}
                              </Button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
        </Card>

    );
  }


export default withRouter(ChangePasswordForm)
