import {
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_SIDEBAR_THEME,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
	TOGGLE_RIGHT_SIDEBAR,
	SHOW_RIGHT_SIDEBAR,
	HIDE_RIGHT_SIDEBAR
} from "./actionTypes";
import Cookies from 'universal-cookie'
const cookies = new Cookies()


export const changeLayout = layout => {
	// cookies.set("layoutType", layout, { path: "/"})
	return ({ type: CHANGE_LAYOUT, payload: layout })
}

export const changeLayoutWidth = width => {
	return ({ type: CHANGE_LAYOUT_WIDTH, payload: width });
}

export const changeSidebarTheme = theme => {
	// cookies.set("SidebarTheme", theme, { path: "/"})
	return ({ type: CHANGE_SIDEBAR_THEME, payload: theme })
}

export const changeSidebarType = (sidebarType, isMobile) => {
	// cookies.set("sidebarType", sidebarType, { path: "/"})
	return ({ type: CHANGE_SIDEBAR_TYPE, payload: { sidebarType, isMobile }})
};

export const changeTopbarTheme = topbarTheme => {
	// cookies.set("topbarTheme", topbarTheme, { path: "/"})
 	return ({ type: CHANGE_TOPBAR_THEME, payload: topbarTheme })
 }


export const toggleRightSidebar = () => ({
	type: TOGGLE_RIGHT_SIDEBAR,
	payload: null
});

export const showRightSidebar = () => ({
	type: SHOW_RIGHT_SIDEBAR,
	payload: null
});

export const hideRightSidebar = () => ({
	type: HIDE_RIGHT_SIDEBAR,
	payload: null
});
