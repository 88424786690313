import React from "react";
import { Row, Col, Container } from "reactstrap";
import translate from 'helpers/Translation/translate';
import { Link } from "react-router-dom";
import GdprLanguage from "Components/Global/GdprLanguage";
import LoginBackground from 'assets/images/LoginBackground.png'

const AuthContainer = ({ children }) => (
  <Row
    style={{
      backgroundColor: "white",
      backgroundSize: "100% auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${LoginBackground})`,
      height: "100vh",
    }}
  >
    <GdprLanguage login />
    <Container className="col-lg-6 center-center">
      {children}
      <p style={{ zIndex: 10 }}>
        {translate("login.go-back")}
        <Link to="/login" className="font-weight-medium text-primary ml-1">
          {translate("login.login")}
        </Link>
      </p>
    </Container>
    <footer className="footer-non-auth">
      <Col lg={12}>
        <div className="text-sm-rightd-sm-block">
          {new Date().getFullYear()} © Ecosystem Software.
        </div>
      </Col>
    </footer>
  </Row>
);

export default AuthContainer;
