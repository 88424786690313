import {
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_REQUEST,
  UPLOAD_LOGO_FAILURE,
  DELETE_LOGO_REQUEST,
  DELETE_LOGO_SUCCESS,
  DELETE_LOGO_FAILURE,
  IMPORT_FILE_REQUEST,
  IMPORT_FILE_SUCCESS,
  IMPORT_FILE_FAILURE,
  GET_WARRANTY_INFO_REQUEST,
  GET_WARRANTY_INFO_SUCCESS,
  GET_WARRANTY_INFO_FAILURE,
  CLEAR_STATE,
} from "./actionTypes";

import qs from "qs";
import { translate, getUrl } from "helpers/helpers";
import axios from "axios";
import Cookies from "universal-cookie";
import { toFormData } from "helpers/Imports/Transformations";

const cookies = new Cookies();
var token = cookies.get("token");

export const getById = (id, model) => {
  return async (dispatch) => {
    dispatch({ type: `GET_${model}_REQUEST`, variable: model });
    try {
      const success = await getUrl(model).get(`${id}`);
      return dispatch({
        type: `GET_${model}_SUCCESS`,
        payload: success.data,
        variable: model
      });
    } catch (error) {
      if (error.response?.status === 403) return null;
      if (error?.response?.status === 404)
        return dispatch({
          type: `GET_${model}_FAILURE`,
          message: error.response?.data?.error?.message,
          variable: model
        });
      else
        return dispatch({
          type: `GET_${model}_FAILURE`,
          message: translate(`message.${model}-failure`),
          variable: model
        });
    }
  };
};

export const getAll = (model) => {
  return async (dispatch) => {
    dispatch({ type: `ALL_${model}_REQUEST`, variable: model });
    try {
      const success = await getUrl(model).get();
      return dispatch({
        type: `ALL_${model}_SUCCESS`,
        payload: success.data,
        variable: model
      });
    } catch (error) {
      if (error?.response?.status === 403) return null;
      else
        return dispatch({
          type: `ALL_${model}_FAILURE`,
          message: translate(`message.${model}-failure`),
          variable: model
        });
    }
  };
};

export const getWithFilter = (model, params) => {
  return async (dispatch) => {
    dispatch({ type: `GET_${model}_REQUEST`, variable: model });
    try {
      const success = await getUrl(model).get("", {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        }
      });
      return dispatch({
        type: `GET_${model}_SUCCESS`,
        payload: success.data,
        variable: model,
      });
    } catch (error) {
      return dispatch({
        type: `GET_${model}_FAILURE`,
        message: translate(`message.${model}-failure`),
        variable: model,
      });
    }
  };
};

export const getWithPagination = (model, url, params) => {
  return async (dispatch) => {
    dispatch({ type: `GET_${model}_REQUEST`, variable: model });
    try {
      let newParams = params;
      if (model === 'assets' && 
        params.filter?.not_on_contract && 
        params.filter?.customer_id && 
        params.search?.includes(' ')) {
        newParams = { ...params, search: null, filter: { serial_numbers: params.search.replace(/\s+/g, ','), ...params.filter } };
      }
      const success = await axios.get(url, {
        params: newParams,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      return dispatch({
        type: `GET_${model}_SUCCESS`,
        payload: success.data,
        variable: model,
      });
    } catch (error) {

      if(error.message === "Request failed with status code 400") {
        return dispatch({
          type: `GET_${model}_FAILURE`,
          warning: translate(`message.sort-failure`),
          variable: model,
        });
      } else {
        return dispatch({
          type: `GET_${model}_FAILURE`,
          message: translate(`message.${model}-failure`),
          variable: model,
        });
      }
    }
  };
};

export const createModel = (model, createThis) => {
  return async (dispatch) => {
    dispatch({ type: `CREATE_${model}_REQUEST`, variable: model });
    try {
      const success = await axios.post(
        `${process.env.REACT_APP_API_URL}/${model}s/create`,
        createThis
      );
      return dispatch({
        type: `CREATE_${model}_SUCCESS`,
        message: translate(`message.create-${model}-success`),
        variable: model,
        id: success.data.id,
        payload: success.data
      });
    } catch (error) {
      if (error?.response?.status === 422)
        return dispatch(
          {
            type: `CREATE_${model}_FAILURE`,
            messages: error.response?.data?.error?.message,
            variable: model,
          });
      else
        return dispatch({
          type: `CREATE_${model}_FAILURE`,
          message: translate(`message.create-${model}-failure`),
          variable: model,
        });
    }
  };
};

export const updateModel = (model, updateThis) => {
  return async (dispatch) => {
    dispatch({ type: `UPDATE_${model}_REQUEST`, variable: model });
    try {
      const success = await axios.put(
        `${process.env.REACT_APP_API_URL}/${model}s/${updateThis.id}`,
        updateThis
      );
      return dispatch({
        type: `UPDATE_${model}_SUCCESS`,
        payload: success.data,
        message: translate(`message.update-${model}-success`),
        variable: model,
      });
    } catch (error) {
      return dispatch({
        type: `UPDATE_${model}_FAILURE`,
        message: translate(`message.update-${model}-failure`),
        variable: model,
      });
    }
  };
};

export const deleteModel = (id, model) => {
  return async (dispatch) => {
    dispatch({ type: `DELETE_${model}_REQUEST`, variable: model });
    try {
      await getUrl(model).delete(`${id}`);
      return dispatch({
        type: `DELETE_${model}_SUCCESS`,
        success: translate(`message.delete-${model}-success`),
        variable: model,
      });
    } catch (error) {
      if (error?.response?.status === 403) {
        return dispatch({
          type: `DELETE_${model}_FAILURE`,
          message: error.response?.data?.error?.message,
          variable: model,
        });
      } else
        return dispatch({
          type: `DELETE_${model}_FAILURE`,
          message: translate(`message.delete-${model}-failure`),
          variable: model,
        });
    }
  };
};

export const uploadImage = (id, image, model) => {
  var bodyFormData = new FormData();
  bodyFormData.append("logo", image);
  return async (dispatch) => {
    dispatch({ type: UPLOAD_LOGO_REQUEST, variable: model });
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/${model}s/${id}/image`,
        data: bodyFormData,
        headers: {
          "content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return dispatch({
        type: UPLOAD_LOGO_SUCCESS,
        message: translate("message.upload-logo-success"),
      });
    } catch (error) {
      if (error.response.status === 422)
        return dispatch(
          {
            type: UPLOAD_LOGO_FAILURE,
            messages: error.response.data.error.message,
            variable: model,
          });
      else
        return dispatch({
          type: UPLOAD_LOGO_FAILURE,
          message: translate("message.upload-logo-failure"),
          variable: model,
        });
    }
  };
};

export const deleteImage = (id, model) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_LOGO_REQUEST });
    try {
      await getUrl(model).delete(`${id}/image`);
      return dispatch({
        type: DELETE_LOGO_SUCCESS,
        message: translate("message.delete-logo-success"),
      });
    } catch (error) {
      return dispatch({ type: DELETE_LOGO_FAILURE });
    }
  };
};

export const getWarrantyInfoFor = (id, model) => {
  return async (dispatch) => {
    dispatch({ type: GET_WARRANTY_INFO_REQUEST });
    try {
      const info = await getUrl(model).get(`${id}/warranty`);
      return dispatch({
        type: GET_WARRANTY_INFO_SUCCESS,
        message: translate("message.warranty-info-calculated"),
        payload: info.data
      });
    } catch (error) {
      return dispatch({ type: GET_WARRANTY_INFO_FAILURE });
    }
  };
};

export const importFile = (data, model) => {

  return async (dispatch) => {
    dispatch({ type: IMPORT_FILE_REQUEST });
    try {
      console.log(data);
      const formData = toFormData(data);
      const success = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/${model}/import`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      return dispatch({
        type: IMPORT_FILE_SUCCESS,
        payload: success.data?.data,
        warning: success.data?.data?.invalid?.assets?.length ? translate("message.csv-import-with-error") : null,
        message: success.data?.data?.invalid?.length < 1 ?  translate("message.csv-import-success") : null,
      });

    } catch (error) {
      if (error?.response?.status === 422)
        return dispatch({
            type: IMPORT_FILE_FAILURE,
            messages: error.response?.data?.error?.message,
          });
      if (error?.response?.status === 404)
        return dispatch(
          {
            type: IMPORT_FILE_FAILURE,
            message: error.response?.data?.error?.message,
          });
      else return dispatch({ type: IMPORT_FILE_FAILURE, message: "" });
    }
  };
};

export const clearModelState = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_STATE });
  };
};
