import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from '../Context';

//AUTH related methods
import Cookies from 'universal-cookie'
const cookies = new Cookies()
var token = cookies.get('token')


const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => (
		<Route
			{...rest}

			render={props => {

				if (isAuthProtected && !token) {
					return ( <Redirect to={{ pathname: "/login", state: { from: props.location } }} />);
				}

				return (
					<Layout>
						<UserContext.Consumer>
							{(me, isAdmin, isCustomer, isReseller)  =>
								<Component
									{...props}
									me={me}
									isAdmin={isAdmin}
									isCustomer={isCustomer}
									isReseller={isReseller}
									/>}
						</UserContext.Consumer>
					</Layout>
				);
			}}
		/>
	);

export default AppRoute;

