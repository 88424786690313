import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  GET_PREDIFINED_PERMISSIONS_REQUEST,
  GET_PREDIFINED_PERMISSIONS_SUCCESS,
  GET_PREDIFINED_PERMISSIONS_FAILURE,
  CLEAR_STATE,
  TWOFA_SETUP_REQUEST,
  TWOFA_SETUP_SUCCESS,
  TWOFA_SETUP_FAILURE,
  TWOFA_ENABLE_REQUEST,
  TWOFA_ENABLE_SUCCESS,
  TWOFA_ENABLE_FAILURE,
  TWOFA_DISABLE_REQUEST,
  TWOFA_DISABLE_SUCCESS,
  TWOFA_DISABLE_FAILURE,
  GENERATE_API_TOKEN_REQUEST,
  GENERATE_API_TOKEN_SUCCESS,
  GENERATE_API_TOKEN_FAILURE,
  GET_API_TOKEN_REQUEST,
  GET_API_TOKEN_SUCCESS,
  DELETE_API_TOKEN_REQUEST,
  DELETE_API_TOKEN_SUCCESS,
  DELETE_API_TOKEN_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  HANDLE_KEY_ACCOUNT_MANAGER_REQUEST,
  HANDLE_KEY_ACCOUNT_MANAGER_SUCCESS,
  HANDLE_KEY_ACCOUNT_MANAGER_FAILURE,
  CREATE_KEY_ACCOUNT_MANAGER_SUCCESS,
  DELETE_KEY_ACCOUNT_MANAGER_REQUEST,
  DELETE_KEY_ACCOUNT_MANAGER_SUCCESS,
  DELETE_KEY_ACCOUNT_MANAGER_FAILURE,
  GET_KEY_ACCOUNT_MANAGERS_REQUEST,
  GET_KEY_ACCOUNT_MANAGERS_SUCCESS,
  GET_KEY_ACCOUNT_MANAGERS_FAILURE,
  DONT_SHOW_TOUR_REQUEST,
  DONT_SHOW_TOUR_SUCCESS,
  DONT_SHOW_TOUR_FAILURE,
  NOTIFY_USER_ABOUT_REPORT_REQUEST,
  NOTIFY_USER_ABOUT_REPORT_SUCCESS,
  NOTIFY_USER_ABOUT_REPORT_FAILURE
} from "./actionTypes";


import { users } from "../api";
import translate from "helpers/Translation/translate";
import axios from 'axios';
import Cookies from 'universal-cookie'
const cookies = new Cookies()


export const dontShowTourAgain = () => {
  return async (dispatch) => {
    dispatch({ type: DONT_SHOW_TOUR_REQUEST });
    try {
      await users.get('me/end-tour');
      return dispatch({ type: DONT_SHOW_TOUR_SUCCESS, message: translate("message.tour-in-profile")})
    } catch(error) {
      return dispatch({ type: DONT_SHOW_TOUR_FAILURE, message: translate("message.something-wrong")});
    }
  }
}

export const updateUser = (user, permissions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    try {
      const success = await users.put(`${user.id}`, {user: user, permissions: permissions});
      return dispatch({ type: UPDATE_USER_SUCCESS, payload: success.data, message: translate("message.update-user-success")})
    } catch (error) {
      return dispatch({
        type: UPDATE_USER_FAILURE,
        message: translate("message.update-user-failure") });
    }
  };
};


export const createUser = (user, permissions) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_USER_REQUEST });
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/users/create`, {user: user, permissions: permissions});
      return dispatch({ type: CREATE_USER_SUCCESS, message: translate("message.create-user-success")})
    } catch (error) { 
      if(error?.response?.status === 422) { return dispatch({ type: UPDATE_PASSWORD_FAILURE, messages: error?.response?.data?.error?.message });
      } else return dispatch({ type: CREATE_USER_FAILURE,  message: translate("message.create-user-failure") });
    }
  };
};

export const getPredifinedPermissions = () => {
  return async (dispatch) => {
    dispatch({ type: GET_PREDIFINED_PERMISSIONS_REQUEST });
    try {
      const permissions = await users.get(`capabilities`);
      return dispatch({
        type: GET_PREDIFINED_PERMISSIONS_SUCCESS,
        payload: permissions.data.permissions_via_roles,
        all_permissions: permissions.data.permissions
      });
    } catch (error) {
      return dispatch({
        type: GET_PREDIFINED_PERMISSIONS_FAILURE,
        message: translate("message.delete-user-failure"),
      });
    }
  };
};

export const TwoFactorAuthSetup = () => {
  return async dispatch => {
    dispatch({ type: TWOFA_SETUP_REQUEST })
    try {
      const res = await users.get(`/two-factor-authentication`);
      return dispatch({ type: TWOFA_SETUP_SUCCESS, payload: res.data })
    } catch (error ){
      return dispatch({type: TWOFA_SETUP_FAILURE , message: translate('message.auth-setup-failed')})
    }
  }
}

export const TwoFactorAuthEnable = (secret, code) => {
  return async dispatch => {
    dispatch({ type: TWOFA_ENABLE_REQUEST })
    try {
      await users.post(`/two-factor-authentication`, {secret: secret, one_time_password: code});
      return dispatch({ type: TWOFA_ENABLE_SUCCESS, message: translate('message.auth-enable-success')})
    } catch (error ){
      return dispatch({type: TWOFA_ENABLE_FAILURE , message: error.response.data.error.message })
    }
  }
}

export const TwoFactorAuthDisable = () => {
  return async dispatch => {
    try {
      dispatch({ type: TWOFA_DISABLE_REQUEST })
      await users.delete(`/two-factor-authentication`);
      return dispatch({ type: TWOFA_DISABLE_SUCCESS, message: translate('message.auth-deleted-success')})
    } catch (error ){
      return dispatch({type: TWOFA_DISABLE_FAILURE , message: error.response.data.error.message })
    }
  }
}

export const generateApiToken = () => {
  return async dispatch => {
    dispatch({ type: GENERATE_API_TOKEN_REQUEST })
    try {
      const res = await users.post(`/api_token`);
      return dispatch({ type: GENERATE_API_TOKEN_SUCCESS, payload: res, message: translate('message.api-token-generated')})
    } catch (error ){
      return dispatch({type: GENERATE_API_TOKEN_FAILURE , message: error.response.data.error.message })
    }
  }
}

export const getApiToken = () => {
  return async dispatch => {
    dispatch({ type: GET_API_TOKEN_REQUEST })
    try {
      const res = await users.get(`/api_token`);
      return dispatch({ type: GET_API_TOKEN_SUCCESS, payload: res.data.api_token})
    } catch (error ){
      cookies.remove("token", { path: "/"});
      const token = cookies.get('token')
      if(!token) window.location = '/login'
      return error;
    }
  }
}

export const deleteApiToken = () => {
  return async dispatch => {
    dispatch({ type: DELETE_API_TOKEN_REQUEST })
    try {
      await users.delete(`/api_token`);
      return dispatch({ type: DELETE_API_TOKEN_SUCCESS,  message: translate('message.api-token-deleted')})
    } catch (error ){
      return dispatch({type: DELETE_API_TOKEN_FAILURE , message: error.response.data.error.message })
    }
  }
}

export const changePassword = passwords => {
  return async dispatch => {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    try {
      const success = await users.put(`/password`, {new_password: passwords.new_password, old_password: passwords.old_password});
      if(success.status === 200) return dispatch({ type: UPDATE_PASSWORD_SUCCESS, message: translate('message.update-password-success') });
    } catch (error) {
      if(error?.response?.status === 422) {
        return dispatch({ type: UPDATE_PASSWORD_FAILURE, messages: error?.response?.data?.error?.message });
      }
      else if(error?.response?.status === 400) {
        return dispatch({ type: UPDATE_PASSWORD_FAILURE, message: error?.response?.data?.message || translate('message.update-password-failure')})
      }
      else return dispatch({ type: UPDATE_PASSWORD_FAILURE, message: translate('message.update-password-failure') });
    }
  };
}

export const handleKeyAccountManager = ( id, customers ) => {
  return async dispatch => {
    dispatch({ type: HANDLE_KEY_ACCOUNT_MANAGER_REQUEST })
      try {
          const success = await axios.post(`${process.env.REACT_APP_API_URL}/users/${id}/key-account-manager`, { customers: customers })
          if (success.status === 201) {
            return dispatch ({ type: CREATE_KEY_ACCOUNT_MANAGER_SUCCESS, message: translate('message.create-kam-success')});
          }
          else if (success.status === 200) {
            return dispatch ({ type: HANDLE_KEY_ACCOUNT_MANAGER_SUCCESS, message: translate('message.update-kam-success')});
          }
        } catch (error) {
            return dispatch ({ type: HANDLE_KEY_ACCOUNT_MANAGER_FAILURE, message: translate('message.create-kam-failure')});
          }
  }
}

export const deleteKeyAccountManager = id => {
  return async dispatch => {
    dispatch({ type: DELETE_KEY_ACCOUNT_MANAGER_REQUEST })
    try {
      await users.delete(`/${id}/key-account-manager`);
      return dispatch({ type: DELETE_KEY_ACCOUNT_MANAGER_SUCCESS,  message: translate('message.role-kam-deleted')})

    } catch (error ){
      return dispatch({type: DELETE_KEY_ACCOUNT_MANAGER_FAILURE , message: error.response.data.error.message })
    }
  }
}

export const getKeyAccountManagers = () => {
  return async dispatch => {
    dispatch({ type: GET_KEY_ACCOUNT_MANAGERS_REQUEST })
      try {
          const KeyAccountManagers = await users.get(`/key-account-managers`)
          return dispatch ({ type: GET_KEY_ACCOUNT_MANAGERS_SUCCESS, payload: KeyAccountManagers.data });
        } catch (error) {
          return dispatch ({ type: GET_KEY_ACCOUNT_MANAGERS_FAILURE, message: translate('message.fetch-kam-failure')});
          }
  }
}

export const notifyUserAboutReport = (id, notify) => {
  return async dispatch => {
    dispatch({ type: NOTIFY_USER_ABOUT_REPORT_REQUEST })
      try {
        const success = await axios.post(`${process.env.REACT_APP_API_URL}/users/${id}/notify`, { notify: notify })
        return dispatch ({ type: NOTIFY_USER_ABOUT_REPORT_SUCCESS, payload: success.data });
        } catch (error) {
          return dispatch ({ type: NOTIFY_USER_ABOUT_REPORT_FAILURE, message: translate('message.user-update-failure')});
          }
  }
}

export const clearState = () => { return async dispatch => {dispatch({ type: CLEAR_STATE})}}