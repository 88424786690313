import React from "react";
import { withRouter } from "react-router-dom";

// Reudx
import { useDispatch, useSelector } from "react-redux";
import { changePassword, TwoFactorAuthEnable, TwoFactorAuthDisable } from "store/user/actions";

// Components
import { PopUpModal } from "Components/Global";
import { ChangePassword, Disable2FA, Enable2FA, GetToken } from './modal/index'

// Functions
import translate from "helpers/Translation/translate";


const UserModal = ({ modal, togglemodal, display, apiToken , secret,  qrcode, getThisUser }) => {

  const dispatch = useDispatch()
  const error = useSelector(state => state.Users.error)
  const errors = useSelector(state => state.Users.errors)
  const success = useSelector(state => state.Users.success)

  const password = (event, values) => {
    dispatch(changePassword(values))
  }

  const enableTwoFA = code => {
    dispatch(TwoFactorAuthEnable(secret, code))
    .then(res => {
      if (res.type === "TWOFA_ENABLE_SUCCESS") {
        getThisUser()
      }
    })
  }

  const disableTwoFA = () => {
    dispatch(TwoFactorAuthDisable()).then(() => { getThisUser()})
  }


  const getTitle = () => {
    switch(display) {
      case 'api' : return 'API TOKEN'
      case 'change-password' : return translate("user.change-password")
      case 'disable-2fa' : return translate('user.disable-2FA')
      case 'enable-2fa' : return translate('user.setup-2FA')
      default: return null
    }
  }

  const getAction = () => {
    switch(display) {
      case 'api' : return null
      case 'change-password' : return null
      case 'disable-2fa' : return null
      case 'enable-2fa' : return null
      default: return null
    }
  }

  const getBody = () => {
    switch(display) {
      case 'api' : return <GetToken apiToken={apiToken} />
      case 'change-password' : return <ChangePassword changePassword={password}/>
      case 'disable-2fa' : return <Disable2FA disableTwoFA={disableTwoFA} />
      case 'enable-2fa' : return <Enable2FA qrcode={qrcode} secret={secret} enableTwoFA={enableTwoFA}/>
      default: return null
    }
  }


    return (
      <PopUpModal
        isOpen={modal}
        togglemodal={togglemodal}
        error={error}
        errors={errors}
        success={success}
        title={getTitle()}
        action={getAction()}
        buttonText={getTitle()}
      >
        {getBody()}
      </PopUpModal>
    );
  }

export default withRouter((UserModal))
