import React, { useState, useEffect } from "react";
import MicrosoftLogin from "react-microsoft-login";
import { useDispatch } from "react-redux";
import { microsoftLogin, logout } from 'store/auth/actions';
import { Button } from 'reactstrap';
import { translate } from "helpers/helpers";

import Cookies from 'universal-cookie'
const cookies = new Cookies()
var token = cookies.get('token')
const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID

const MicrosoftButton = ({ me }) => {

    const [msalInstance, onMsalInstanceChange] = useState();
    const [isMicrosoftUser, setIsMicrosoftUser] = useState(false);
	const dispatch = useDispatch();

    useEffect(() => {
        if(me?.is_microsoft_user) setIsMicrosoftUser(true)

        // If msalInstance is lost due to inactivity and token is still present
        if(!msalInstance && token && isMicrosoftUser) dispatch(logout()).then(() => { window.location = '/login' })
      }, [me, msalInstance])

    const loginHandler = (err, data, msal) => {
        if (!err && data) {
          onMsalInstanceChange(msal);
        }
        if(data?.accessToken && !token) dispatch(microsoftLogin(data.accessToken))
        .then(res => {
            if(res?.type === "MICROSOFT_LOGIN_SUCCESS") {
                if(res?.payload?.getting_started === 0) {
                    window.location = "/"
                } else {
                    window.location = "/get-started"
                }
            }
        })
      };

    const microsoftLogoutHandler = () => {
        if(msalInstance) {
            msalInstance.logout();
        }
    };

    const ecosystemLogoutHandler = () => {
        dispatch(logout()).then(() => { window.location = '/login' })
    };

    return (
        msalInstance && !me ? // Microsoft account does not exist in system => logout from Microsoft in order to change account
        <Button color="light" onClick={microsoftLogoutHandler} >
            <i className="bx bx-power-off font-size-16 align-middle mr-1"></i>
            <span>{translate('login-other-account')}</span>
        </Button>
        :
        msalInstance ? ( // Microsoft user logged in
        <Button color="light" onClick={microsoftLogoutHandler} > 
            <i className="bx bx-power-off font-size-16 align-middle mr-1"></i>
            <span>{translate('logout')}</span>
        </Button>
      ) : (me && !isMicrosoftUser) ? // Ecosystem user logged in
        <Button color="light" onClick={ecosystemLogoutHandler} >
            <i className="bx bx-power-off font-size-16 align-middle mr-1"></i>
            <span>{translate('logout')}</span>
         </Button>
        : (
        <MicrosoftLogin clientId={MICROSOFT_CLIENT_ID} authCallback={loginHandler} />
      )
    )

}

export default MicrosoftButton