
import React from "react";
import AuthContainer from "../../Containers/AuthContainer";
import ChangePasswordForm from "./Components/ChangePasswordForm";
import ForgetPasswordForm from "./Components/ForgetPasswordForm";

const ForgetPassword = ({ location }) => (
    <AuthContainer>
      {location.pathname === '/forget-password' ? <ForgetPasswordForm /> : <ChangePasswordForm />}
      </AuthContainer>
    );

export default ForgetPassword

