import {
	CHANGE_LOCALE,
	GET_ME_REQUEST,
	GET_ME_SUCCESS,
} from "./actionTypes";
import { users } from "../api";

import Cookies from 'universal-cookie'
const cookies = new Cookies()
var locale = cookies.get('locale')

export const changeLocale = lang => {

	if(locale) cookies.remove('locale', { path: '/' });
	cookies.set('locale', lang)
	return async dispatch => {
		try { dispatch({type: CHANGE_LOCALE, payload: lang})
		} catch (error) {
			return error;
		}
	}
}


export const getMe = () => {
	return async (dispatch) => {
	  try {
		dispatch({ type: GET_ME_REQUEST });

		const user = await users.get(`me`);
		if(Object.keys(user?.data).length < 1) {
		  cookies.remove("token", { path: "/"});
		  const token = cookies.get('token')
		  if(!token) window.location = '/login'
		} else {
		  return dispatch({ type: GET_ME_SUCCESS, payload: user.data });
		}
	  } catch (error){
		if (error?.response?.status === 401) {
		  cookies.remove("token", { path: "/"});
		  const token = cookies.get('token')
		  if(!token) window.location = '/login'
		}
	  }
	};
  };