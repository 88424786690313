import { Ability, AbilityBuilder } from "@casl/ability"
import { capitalize } from "lodash"
import store from "store/index"

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === "string") {
    return item
  }
  return item.__type
}

const ability = new Ability([], { subjectName })

let currentAuth;
store.subscribe(() => {
    const prevAuth = currentAuth;
    currentAuth = store.getState().Global.me;
    if (((prevAuth !== currentAuth) && (Object.keys(currentAuth).length > 0))) {
        ability.update(defineRulesFor(currentAuth))
      }
  });


function defineRulesFor(role) {
    const { can, rules } = new AbilityBuilder();
    var d = {};
    for(var i=0;i < role.has_permissions.length; ++i) {
        var perms = role.has_permissions[i].split('_');
        var perm = perms[0];
        var type = capitalize(perms[1]);
        d[type] =  d[type] || [];
        d[type].push(perm);
    }
    for(const type in d) {
        can(d[type], type)
    }
    return rules;
}


export default ability;


