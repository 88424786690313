// @flow
import { 
	CHANGE_LOCALE,
	GET_ME_REQUEST,
	GET_ME_SUCCESS,
 } from "./actionTypes";

const lang = navigator.language


const INIT_STATE = {
	locale: lang === 'sv' ? 'sv' : 'en',
	messages: [],
	me: {},
	isAdmin: false,
	isReseller: false,
	isCustomer: false
};

const Global = ( state = INIT_STATE, action ) => {
	switch (action.type) {
		case CHANGE_LOCALE:
			return {
				...state,
				locale: action.payload
			};
	    case GET_ME_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case GET_ME_SUCCESS:
            return {
                ...state,
                isFetching: false,
                me: action.payload,
                isAdmin: action.payload.type === 'admin' ? true : false,
                isReseller: action.payload.type === 'reseller' ? true : false,
                isCustomer: action.payload.type === 'customer' ? true : false,
            };
		default:
			return state;
	}
};

export default Global;
