/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {  Link } from 'react-router-dom';
// Redux
import { useSelector, useDispatch} from "react-redux";
import { login, TwoFactorAuthLogin, clearState } from 'store/auth/actions';
// Components
import { Row, Col, FormGroup, CardBody } from "reactstrap";
import { AvForm } from 'availity-reactstrap-validation';
import { Alerts, GdprLanguage } from 'Components/Global';
import TextInputField from "Components/Form/TextInputField";
import TwoFactorLoginModal from './Components/TwoFactorLoginModal';
import LoginImage from './Components/LoginImage';
import MicrosoftButton from "Components/Global/MicrosoftButton";
// Helpers
import translate from 'helpers/Translation/translate';
// Img
import ecosystem from 'assets/images/ecosystem-green.png'

const DemoMode = process.env.REACT_APP_DEMO_MODE
const lastRelease = new Date(process.env.REACT_APP_LAST_RELEASE);

const Login = () => {

    const error = useSelector(state =>  state.Login.error)
    const success = useSelector(state => state.Login.success)

    const [ modal, setModal ] = useState(false)
    const [ values, setValues] = useState('')

	const dispatch = useDispatch();

    useEffect(() => {
        return () => {
          dispatch(clearState())
        }
      }, [])

    const handleValidSubmit = (event, values) => {
        dispatch(clearState())
        dispatch(login(values)).then(res => {
            if(res?.type === "DISPLAY_AUTH_MODAL") {
                setModal(true)
                setValues(values)
            }
            else if(res?.type === "LOGIN_SUCCESS") {

                if(res?.payload?.getting_started === 0) {
                    const { last_login } = res.payload;
                    window.location = !last_login || lastRelease > new Date(last_login) ? '/release-notes' : "/";
                } else {
                    window.location = "/get-started"
                }
            }
        })
    }

    const enterCode = code => {
        dispatch(TwoFactorAuthLogin(values, code)).then(res => {
            if(res?.type === "TWOFA_LOGIN_SUCCESS") {
                
                setModal(false)

                const { last_login } = res.payload;
                window.location = !last_login || lastRelease > new Date(last_login) ? '/release-notes' : "/";
            } else {
                setModal(true)
            }
          })
    }

    const togglemodal = () => {
        setModal(!modal)
    };

        return (
                <Row className="ecosystem-login-container">
                    <GdprLanguage login/>
                    <Row className="d-flex justify-content-end">
                        <Col lg={5} className="login-form">
                            <Col lg={8} md={8} sm={12} className="float-right">
                                <img src={ecosystem} className="img-fluid mt-3"/>
                            </Col>
                            <Col lg={8} md={8} sm={12} className="float-right">
                                <CardBody>
                                    <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit} >
                                        <Alerts error={error} success={success} />
                                        <FormGroup>
                                            <TextInputField
                                                name="email"
                                                label={'login.mail-login'}
                                                value=""
                                                type="email"
                                                placeholder={'form.enter-email'}
                                                errorMessage={'form.enter-email'}
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <TextInputField
                                                name="password"
                                                label={'login.password'}
                                                value=""
                                                type="password"
                                                placeholder={'form.enter-your-password'}
                                                errorMessage={'form.enter-your-password'}
                                                validate={{ minLength: {value: 6} }}
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <button
                                                className="btn btn-success btn-block waves-effect waves-light mt-3"
                                                type="submit">
                                                {translate('login.login')}
                                            </button>
                                        </FormGroup>

                                        {!DemoMode &&
                                        <div className="text-center ">
                                            <Link to="/forget-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> {translate('login.forgot')}</Link>
                                        </div>}
                                    </AvForm>

                                    {/* This Microsoft Button is ready to use. Authentication is prepared in the backend. */}
                                    {/* <FormGroup className="mt-5 text-center col-lg-12">
                                        <MicrosoftButton error={error} />
                                    </FormGroup> */}
                                </CardBody>
                            </Col>
                        </Col>
                        <Col lg={6} md={6} className="d-none d-lg-block">
                            <LoginImage />
                        </Col>
                    </Row>
                    <TwoFactorLoginModal
                        values={values}
                        modal={modal}
                        togglemodal={togglemodal}
                        getCode={enterCode}
                        error={error}
                        />
                </Row>
        );
    }


export default Login;

