import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    MICROSOFT_LOGIN_REQUEST,
    MICROSOFT_LOGIN_SUCCESS,
    MICROSOFT_LOGIN_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    TWOFA_LOGIN_REQUEST,
    TWOFA_LOGIN_SUCCESS,
    TWOFA_LOGIN_FAILURE,
    LOST_DEVICE_REQUEST,
    LOST_DEVICE_SUCCESS,
    LOST_DEVICE_FAILURE,
    DISPLAY_AUTH_MODAL,
    LOGOUT_REQUEST,
    CLEAR_STATE
 } from './actionTypes';

import Cookies from 'universal-cookie'
import translate from 'helpers/Translation/translate';
import { auth } from "../api";
import axios from 'axios';
const cookies = new Cookies()

export const logout = () => {
  return async dispatch => {
    cookies.remove("token", { path: "/"});
    const token = cookies.get('token')
    if(!token) return dispatch({ type: LOGOUT_REQUEST, message: translate('message.logout') })
  }
}

export const login = creds => {

  return async dispatch => {

    const receiveToken = response  => {
      cookies.set("token", response.data.access_token, { path: "/", maxAge: 86399 });
      return dispatch ({ type: LOGIN_SUCCESS, payload: response.data.user });
    }
    try {
      dispatch({ type: LOGIN_REQUEST });
      cookies.remove("token", { path: "/"});
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {email: creds.email, password: creds.password})
      if(res?.data.two_factor === true) return dispatch ({ type: DISPLAY_AUTH_MODAL })
      if(res?.data.access_token) return receiveToken(res);


    } catch(error) {
      if(error?.message === 'Failed to fetch') return dispatch ({ type: LOGIN_FAILURE, message: translate('message.system-error') })
      if(error?.response ) return dispatch ({ type: LOGIN_FAILURE, message: error?.response?.data?.error?.message })
      else  return dispatch({ type: LOGIN_FAILURE, message: translate('message.no-internet')  })
    }
  }
};

export const microsoftLogin = token => {

  return async dispatch => {

    const receiveToken = response  => {
      cookies.set("token", response.data.access_token, { path: "/", maxAge: 86399 });
      return dispatch ({ type: MICROSOFT_LOGIN_SUCCESS, payload: response.data.user });
    }

    try {
      dispatch({ type: MICROSOFT_LOGIN_REQUEST });
      const res = await auth.post(`/microsoft-login`, { token: token });
      if(res?.data.access_token) return receiveToken(res);
    } catch (error) {
      dispatch({ type: MICROSOFT_LOGIN_FAILURE, message: 'User not authenticated' });

    }
  }
}

export const forgotPassword = email => {
  return async dispatch => {

    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });

      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset`, {
        method: "POST",
        body: JSON.stringify(email),
        headers: { "Content-Type": "application/json" }
      })
      if (res.status === 200) return dispatch({ type: FORGOT_PASSWORD_SUCCESS, message: translate('email.sent') });
      if (res.status === 422) return dispatch({ type: FORGOT_PASSWORD_FAILURE, message: translate('message.email-not-existing') });
      if (res.status === 404) return dispatch({ type: FORGOT_PASSWORD_FAILURE, message: translate('message.email-not-existing') });

    } catch ( error ) {
      return dispatch ({ type: FORGOT_PASSWORD_FAILURE, message: translate('email.something-wrong')})
    }

  }
};

export const changePassword = (email, token) => {

  return async dispatch => {

    try {
      dispatch({ type: CHANGE_PASSWORD_REQUEST });

      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset/${token}`, {
        method: "POST",
        body: JSON.stringify(email),
        headers: { "Content-Type": "application/json" }
      })
      if (res.status === 200) return dispatch({ type: CHANGE_PASSWORD_SUCCESS, message: translate('login.password-was-updated') });
      if (res.status === 500) return dispatch({ type: CHANGE_PASSWORD_FAILURE, message: translate('login.token-expired') });

    } catch ( error ) {
      return dispatch ({ type: CHANGE_PASSWORD_FAILURE, message: translate('email.something-wrong')})
    }

  }
};

export const TwoFactorAuthLogin = (values, code) => {
  return async dispatch => {

    const receiveToken = response  => {
      cookies.set("token", response.data.access_token, { path: "/", maxAge: 86399 });
      return dispatch ({ type: TWOFA_LOGIN_SUCCESS, payload: response.data.user });
    }
    try {
      dispatch({ type: TWOFA_LOGIN_REQUEST })

      const res = await auth.post(`/login`, {email: values.email, password: values.password, one_time_password: code});
      if(res?.data.access_token) return receiveToken(res);

    } catch (error ){
      return dispatch({type: TWOFA_LOGIN_FAILURE , message: error.response.data.error.message })

    }
  }
}

export const lostDevice = (values) => {
  return async dispatch => {
    try {
      dispatch({ type: LOST_DEVICE_REQUEST })
      const res = await auth.post(`/reset/two-factor`, {email: values.email, password: values.password});
      return dispatch({ type: LOST_DEVICE_SUCCESS, message: res?.data?.message })

    } catch (error) {
      return dispatch({ type: LOST_DEVICE_FAILURE, message: translate('email.something-wrong') })

    }
  }
}

export const clearState = () => { return async dispatch => { return dispatch({ type: CLEAR_STATE}) }}

