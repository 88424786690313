import axios from 'axios';
import Cookies from 'universal-cookie'
const cookies = new Cookies()
var token = cookies.get('token')


axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

// const responseErrorHandler = error => {
// if (error.response) {
//     console.error(error.response.data);
//     console.error(error.response.status);
//     console.error(error.response.headers);
//   } else if (error.request) {
//     console.error(error.request);
//   } else {
//     console.error('Error', error.message);
//   }
//   console.error(error.config);
//   return Promise.reject(error);
// }

// const responseErrorHandler = error => {
//   if (error.response.status === 401) {
//     cookies.remove("token", { path: "/"});
//     window.location = '/login'
//   }

//   return Promise.reject(error);
// }

// axios.interceptors.response.use(error => responseErrorHandler(error));


const auth = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/auth/`,
});

const contracts = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/contracts/`,
});

const contractsUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/contracts/`,
});

const assets = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/assets/`,
});

const assetsUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/assets/`,
});

const customers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/customers/`,
});

const forms = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/forms/`,
});

const quotes = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/quotes/`,
});

const resellers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/resellers/`,
});

const resellersUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/resellers/`,
});

const reports = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/moreco-reports/`,
});


const users = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/users/`,
});

const usersUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/users/`,
});

const uc = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/io/`,
});

const icecat = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/icecat/`,
});

const financiers = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/financiers`,
});

const tariffs = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/tariffs`,
});

const suppliers= axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/suppliers`,
});

const products= axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/products`,
});

const productsUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/products`,
});

const dashboard = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/dashboard`,
});

const agreements = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/support-agreements`,
});

const demo = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/tools/demo/reset`,
});

const status = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/assets/statuses`,
});


const statusUrl = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/assets/statuses`,
});

const tools = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/tools`,
});


// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   console.log(response)
//   return response;
// }, function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   console.log(error)
//   console.log(error.toJSON());
//   return Promise.reject(error);
// });

export {
  contracts,
  assets,
  assetsUrl,
  customers,
  resellersUrl,
  contractsUrl,
  statusUrl,
  productsUrl,
  usersUrl,
  forms,
  quotes,
  resellers,
  users,
  uc,
  icecat,
  financiers,
  tariffs,
  suppliers,
  dashboard,
  auth,
  agreements,
  products,
  reports,
  demo,
  status,
  tools
}
