import { LOCALES } from '../locales'

var trans = {
    [LOCALES.ENGLISH]: {
        'DELETE_RESELLER_SUCCESS' : 'Reseller deleted succesfully',
        'GET_ASSET_FAILURE' : 'This asset does not exist',
        'GET_ASSETS_FAILURE' : 'Assets could not be fetched',
        'GET_HISTORY_FAILURE': 'History could not be fetched',
        'GET_HISTORY_FOR_ASSET_FAILURE': 'History for this asset could not be fetched',
        'IMPORT_ASSETS_CSV_FAILURE' : 'Import of assets failed',
        'UPDATE_ASSET_SUCCESS': 'Asset updated',
        'UPDATE_ASSET_FAILURE': 'Asset could not be updated',
        'ACTIVATE_ASSET_SUCCESS' : 'Asset is now marked as in use',
        'ACTIVATE_ASSET_FAILURE' : 'Something went wrong, the asset is still inactive',
        'DEACTIVATE_ASSET_SUCCESS': 'Asset is now marked as inactive',
        'DEACTIVATE_ASSET_FAILURE': 'Something went wrong, the asset is still marked as in use',
        'DELETE_ASSET_FAILURE' : 'Asset could not be deleted',
        'DELETE_ASSET_SUCCESS' : 'Asset deleted',
        'DELETE_ASSETS_FAILURE' : 'Assets could not be deleted',
        'DELETE_ASSETS_SUCCESS' : '{assets} Assets deleted',
        'INVENTORY_ASSET_SUCCESS' : 'Stock taking successful',
        'INVENTORY_ASSET_FAILURE' : 'Stock taking failed',
        'INVENTORY_ASSETS_SUCCESS' : 'Stock taking of {assets} assets',
        'INVENTORY_ASSETS_FAILURE' : 'Stock taking of assets failed',
        'CREATE_ASSET_FAILURE' : 'Something went wrong, please try again',
        'CREATE_ASSET_SUCCESS' : '{assets} Assets created!',
        'GET_ICECAT_DATA_FAILURE' : 'Could not contact icecat data base, please try again',
        'SYNC_ITGLUE_SUCCESS' : 'Synchronization completed',
        'IMPORT_ASSETS_CSV_SUCCESS' : '{assets} assets imported',

        'GET_WIDGETS_FAILURE' : 'Dashboard data could not be loaded',

        'product.editable-help': 'All manually created products are editable',
        'product.check-icecat': 'Search for product info',
        'product.no-eol' : 'Categories without EOL',
        'product-created-succesfully' : 'Product created successfully',
        'product.manual': 'Manual',

        'message.sort-failure' : 'This column sort is not available',
        'message.role-kam-deleted' : 'The role Key Account manager was deleted',
        'message.sync-icecat-success' : 'Products with available data on Icecat have been updated',
        'message.csv-import-with-error' : 'The file was partially imported. See invalid rows',
        'message.warranty-info-calculated' : 'Warranty information fetched!',
        'message.create-assets/statuse-failure' : 'Status creation failed',
        'message.report-generated-with-errors' : 'Report generated with incomplete data',
        'message.logout' : 'Logging out...',
        'message.products-failure' : 'Fetching products failure',
        'message.sync-it-glue-failure' : 'ITGlue sync failed',
        'message.delete-customer-failure' : 'Customer could not get deleted',
        'message.product-failure' : 'Failed to fetch products',
        'message.mail-sent' : 'Mail has been sent',
        'message.excel-lot-failure': 'Excel file generation failed',
        'message.wrong-hdd-file' : 'The file imported as hard drive audit report is not correct',
        'message.wrong-lot-file' : 'The file imported as lot detail audit report is not correct',
        'message.no-lot-report' : 'No report available',
        'message.no-annual-report' : 'No annual report available for this customer',
        'message.lot-failure' : 'Lot reports could not be fetched',
        'message.excel-annual-failure' : 'Excel report could not be downloaded, please try again.',
        'message.delete-report-failure' : 'Deletion of report failed',
        'message.delete-report-success' : 'Successful deletion of report',
        'message.report-success' : 'Report generated with success',
        'message.report-failure' : 'Report generation failed, please try again',
        'message.blancco-report-failure' : 'Blancco report could not be fetched',
        'message.asset-import-to-contract' : '{assets} assets imported to contract',
        'message.asset-import-fail': 'Asset import failed',
        'message.create-assets/statuse-success' : 'Status created',
        'message.update-assets/statuse-success' : 'Status updated',
        'message.csv-import-success' : 'CSV file imported succesfully',
        'message.delete-product-failure' : 'Product could not be deleted',
        'message.update-product-success' : 'Product updated',
        'message.create-contract-success' : 'Contract created',
        'message.create-contract-failure' : 'Contract could not be created',
        'message.delete-contract-success' : 'Contract deleted',
        'message.update-contract-success' : 'Contract updated',
        'message.create-reseller-success' : 'Reseller created',
        'message.create-reseller-failure' : 'Reseller could not be created',
        'message.create-customer-success' : 'Customer created',
        'message.create-customer-failure' : 'Customer could not be created',
        'message.delete-logo-success' : 'Image deleted',
        'message.delete-logo-failure' : 'Image could not be deleted',
        'message.delete-asset-success' : 'Asset deleted',
        'message.delete-asset-failure' : 'Asset could not be deleted',
        'message.update-asset-success' : 'Asset updated',
        'message.update-asset-failure' : 'Asset could not be updated',
        'message.create-asset-success' : 'Asset/s created',
        'message.create-asset-failure' : 'Asset/s could not be created',

        'message.asset-failure' : 'Asset could not be fetched',
        'message.history-failure' : 'History could not be loaded',

        'message.tour-in-profile' : 'Done! You can always find the guide in your profile dropdown!',
        'message.something-wrong' : 'Something went wrong, please try again',
        'message.password-old-new-same' : 'The new password and old password must be different',
        'message.system-error' : 'Please try again later',
        'message.data-failure' : 'Failed to fetch dashboard data',
        'message.contract-failure' : 'This contract does not exist',
        'message.contracts-failure' : 'Failed to get contracts',
        'message.users-failure' : 'Failed to get users',
        'message.user-failure' : 'User does not exist',
        'message.reseller-failure' : 'Reseller does not exist',
        'message.resellers-failure' : 'Failed to get resellers',
        'message.update-reseller-failure': 'Failed to update the reseller',
        'message.delete-product-success' : 'Product deleted!',
        'message.credit-check-failure' : 'Failed to get credit checks',
        'message.contract-log-failure' : 'Failed to fetch contract log',
        'message.contracts-log-failure' : 'Failed to fetch contract logs',
        'message.customer-create-success' : 'Customer created!',
        'message.customer-failure' : 'Failed to get customer',
        'message.customer-delete-failure' : 'Failed to delete customer, please try again!',
        'message.customers-failure' : 'Failed to get customers',
        'message.delete-user-success' : 'The user is now deleted.',
        'message.delete-user-failure' : 'Something went wrong and the user was not deleted, please try again',

        'message.contract-delete-success' : 'Contract successfully deleted',
        'message.contract-delete-fail' : 'Could not delete contract',
        'message.archive-contract-success' : 'Contract is now archived',
        'message.archive-contract-failure' : 'Contract could not be archived',
        'message.contract-create-fail' : 'Could not create contract',
        'message.contract-create-success' : 'Contract was successfully created',
        'message.get-reseller-customers-failure' : 'Could not fetch tis reseller\'s customers',
        'message.contract-update-failure' : 'Could not updated contract',
        'message.contract-update-success' : 'Contract was updated',
        'message.delete-reseller-failure' : 'Could not delete reseller',
        'message.delete-reseller-success' : 'Reseller successfully deleted!',
        'message.reseller-create-fail' : 'Could not create reseller',
        'message.uc-lookup-failure' : 'Could not find any information, please check if you have the - sign, remove it and try again.',
        'message.icecat-fail' : 'Could not make this request',
        'message.icecat-no-info' : 'No information could be fetched, please fill in the product information manually below',
        'message.get-banks-failure' : 'Could not get financiers',
        'message.get-bank-failure' : 'Could not get the financier',
        'message.update-bank-failure': 'The bank could not be updated',
        'message.create-bank-failure': 'The bank could not be created',
        'message.delete-bank-failure' : 'Could not delete bank',
        'message.get-stibor-failure' : 'Could not get stibor rates',
        'message.get-suppliers-failure' : 'Could not get suppliers',
        'message.delete-supplier-failure' : 'Supplier could not be deleted',
        'message.create-supplier-failure' : 'Supplier could not be created',
        'message.update-supplier-failure' : 'Supplier could not be updated',
        'message.get-blancco-report-fail' : 'Request to the Blancco API failed',
        'message.update-customer-failure' : 'The customer could not be updated',
        'message.update-supplier-success' : 'The supplier was updated!',
        'message.update-bank-success' : 'The information about this bank was updated!',
        'message.update-quote-failure' : 'The quote could not be updated',
        'message.update-quote-success' : 'The quote was updated!',
        'message.handle-expiration-failure' : 'Could not update the contract',
        'message.handle-expiration-success' : 'New expiration option is confirmed',
        'message.update-customer-success' : 'Customer was updated',
        'message.upload-logo-success' : 'Image uploaded',
        'message.updload-logo-failure' : 'Image not uploaded',
        'message.asset-create-success' : '{assets} Assets were created!',
        'message.asset-create-success-single' : 'Asset was created!',
        'message.delete-customer-success' : 'Customer was deleted!',
        'message.delete-bank-success' : 'Bank was deleted!',
        'message.create-supplier-success' : 'Supplier was created',
        'message.delete-supplier-success' : 'Supplier was deleted',
        'message.update-tariff-success': 'The value was updated',
        'message.update-tariff-failure': 'Something went wrong, please try again',
        'message.buyout-quote-failure' : 'Something went wrong, please try again',
        'message.buyout-quote-success' : 'A request for a buy out quote was sent',
        'message.handle-termination-success' : 'New date for termination of contract was selected',
        'message.handle-termination-failure' : 'Something went wrong, please try again',
        'message.icecat-connection-failure' : 'Could not contact Icecat, Please try again',
        'message.update-user-success' : 'The user was updated!',
        'message.update-user-failure' : 'The user could not be updated',
        'message.asset-log-no-auth' : 'You don\'t have permissions to view the log for this asset',
        'message.get-blancco-report-no-hit' : 'Asset controlled in Blancco database without any result',
        'message.asset-deactivate-success': 'The asset is now set to inactive',
        'message.products-fail' : 'Could not fetch products',
        'message.market-value-import-fail' : 'Could not import file',
        'message.update-market-value-failure' : 'Market value could not be updated',
        'message.update-eol-failure' : 'EOL could not be updated',
        'message.update-eol-success' : 'EOL was updated',
        'message.market-value-import-success' : 'The estimated market values were imported succesfully',
        'message.create-user-success' : 'User successfully created. An email has been sent to the user.',
        'message.create-user-failure' : 'The user could not be created',
        'message.update-reseller-success' : 'Reseller updated successfully',
        'message.asset-edit-success' : 'Asset was updated',
        'message.update-market-value-success' : 'Estimated market value was updated',
        'message.auth-enable-success' : 'Two factor authentication is now activated!',
        'message.auth-deleted-success' : 'Two factor authentication is now deactivated and you can sign in with just your password!',
        'message.wrong-password' : 'Could not match credentials.',
        'message.api-token-generated' : 'API key was created succesfully!',
        'message.api-token-deleted' : 'API key was deleted!',
        'message.update-password-failure' : 'Password was not upated, please try again',
        'message.update-password-success' : 'You can now start using your new password!',
        'message.email-not-existing' : 'There is no account registered with this email',
        'message.upload-logo-failure' : 'Logo could not get uploaded',
        'message.no-internet' : 'Could not connect to server, please try again later.',
        'message.demo' : 'Some features like email and warranty lookups are inactive in this demo.',
        'message.status-exists' : 'Status allready exists!',
        'message.status-created' : 'Status created!',
        'message.get-status-failure' : 'Status fetching failed',
        'message.create-status-failure' : 'Status creation failed',
        'message.delete-status-failure' : 'Status could not get deleted',
        'message.delete-status-success' : 'Status was deleted',
        'message.status-edit-success' : 'Status updated successfully',
        'message.status-edit-fail' : 'Status update failed',
        'message.message.sync-it-glue-success' : 'Synchronization completed!',
        'message.demo-reset-success' : 'Demo data reset successfully',
        'message.demo-reset-failure' : 'Demo data reset failed',
        'message.demo-reset' : 'Reset demo data',
        'message.create-kam-success' : 'Key account manager created successfully',
        'message.create-kam-failure' : 'Failed to create new key account manager',
        'message.fetch-kam-failure' : 'Failed to fetch Key account managers',
        'message.role-deleted-success': 'The user is no longer a key account manager and can now see all customers.',
        'message.update-kam-success': 'Key account manager updated successfully',
        'message.page-not-found' : 'Page not found',
        'message.assets-removed-success' : 'Assets removed from contract.',
        'message.assets-removed-failure' : 'Assets could not be removed from contract. Please try again',
        'message.assets-delete-success' : '{assets} assets deleted successfully!',
        'message.assets-delete-fail' : 'Selected assets could not get deleted. Please try again.',
        'message.assets-connected-success' : 'Assets successfully connected to contract',
        'message.assets-connected-failure' : 'Assets could not get connected to contract',
        'message.assets-moved-success' : 'Assets moved successfully',
        'message.contract-extend-success' : 'Contract extended',
        'message.contract-extend-failure' : 'Contract extension failed',
        'message.create-product-success' : 'Product was created!',
        'message.create-product-failure' : 'Something went wrong, please try again',
        'message.update-product-failure' : 'Something went wrong, please try again',
        'message.delete-image-success' : 'Image deleted',
        'message.delete-image-failure' : 'Image could not get deleted',
        'message.years-failure' : 'Years could not be fetched',
        'message.annual-report-failure' : 'Report could not be fetched',
        'message.morecorelotports-failure' : 'Audit reports could not be fetched',

        'message.asset-csv-failed' : 'CSV export failed, please try again.',
        'message.assets-failure' : 'Assets could not be fetched',
        'message.reports-failure' : 'Reports could not be fetched',

        'address.address': 'Address',
        'address.zip': 'Zip',
        'address.city': 'City',
        'address.email': 'Email',
        'address.phone': 'Phone',
        'handle-emv' : 'Handle EMV',
        'created-at' : 'Created at',
        'updated-at' : 'Updated at',
        'remove-image' : 'Remove image',
        'move-to' : 'Move to',
        'clear' : 'Clear',
        'graph' : 'Graph',
        'lost-device' : 'Lost your device?',
        'delete-image' : 'Delete image',
        'change-category' : 'Change category',
        'monthly' : 'Monthly',
        'new-search' : 'New search',
        'search' : 'Search',
        'no-eol-data' : 'EOL can not be calculated without a selected category',
        'remove' : 'Remove',
        'substarts' : 'Sub starts',
        'clear-filters': 'Clear filters',
        'sending' : 'Sending...',
        'from' : 'from',

        'agreement.agreements' : 'Support agreements',
        'agreement.list' : 'Support agreements list',
        'agreement.create' : 'Add support agreement',

        'asset.enter-product' : 'Enter the product',
        'asset.enter-product-info' : 'Enter the product EAN or supplier with supplier code to fetch available product information.',

        'asset.manual-warranty' : 'Manual warranty',
        'asset.total' : 'Total in system',
        'asset.new-this-month' : 'New assets this month',
        'asset.market-value' : 'Estimated market value',
        'asset.create-manually' : 'Create assets manually',
        'asset.create-manually-text' : 'If you don\'t want to use the import tool you can always add your assets by filling in the information on the create Asset page.',
        'asset.computer-name' : 'Computer name',
        'asset.mac-address' : 'Mac address',
        'asset.imei-number': 'IMEI Number',
        'asset.no-delivery-date' : 'Delivery date missing',
        'asset.age' : 'Age',
        'asset.monthly-cost' : 'Monthly cost',
        'asset.life-cycle-data': 'Life cycle information',
        'asset.no-age' : 'Missing delivery date',
        'asset.assets' : 'Assets',
        'asset.filters' : 'Filters',
        'asset.search-serial' : 'Search for serial numbers',
        'asset.hide' : 'Hide assets',
        'asset.display' : 'Display assets',
        'asset.import' : 'Import assets with CSV file',
        'asset.import-title' : 'Import and mass updated assets',
        'asset.import-text1' : 'You can import a CSV-file to automatically map the assets to a customer or a contract. In order to do this you will need the contract id or customer id. You will find them on their respective info page.',
        'asset.import-text2' : 'If you are importing existing serial numbers the information will be updated. This tool can therefore be used to add new assets and updated previously added ones.',
        'asset.help' : 'Help',
        'asset.help-text' : 'In order to import assets the file must be formatted correctly.',
        'asset.download-purchased' : 'Download example file - purchased assets',
        'asset.download-rented' : 'Download example file - rented assets',
        'asset.serial' : 'Serial number',
        'asset.contract' : 'Contract ID (found on contract page)',
        'asset.customer' : 'Customer ID (found on customer card)',
        'asset.user' : 'User',
        'asset.cost' : 'Cost center',
        'asset.montlhy' : 'Montly cost',
        'asset.start' : 'Date, YYY-MM-DD',
        'asset.warranty-date' : 'Date, YYY-MM-DD',
        'asset.title' : 'Title',
        'asset.description' : 'Description',
        'asset.picture' : 'Picture',
        'asset.product-code' : 'Product code',
        'asset.product-number': 'Product number',
        'asset.ean' : 'EAN',
        'asset.category' : 'Category',
        'asset.supplier/manufacturer' : 'Supplier/Manufacturer',
        'asset.more-info' : 'More info',
        'asset.technical-pdf' : 'Technical PDF',
        'asset.web' : 'Website',
        'asset.assets-show' : 'Assets displayed belong to contract ',
        'asset.show-all' : 'Show all ...',
        'asset.amount' : 'Number of assets',
        'asset.amount-help' : 'The graph illustrates the amount of assets handled by the customer during this month',
        'asset.spare-parts' : 'Spare parts S/N',
        'asset.spare-parts-2' : 'Spare parts P/N',
        'asset.warranty' : 'Warranty',
        'asset.warranty-overdue' : 'Overdue',
        'asset.warranty-ends' : 'Warranty ends',
        'asset.warranty-search' : 'Warranty Lookup status',
        'asset.link-opens' : 'Links will be opened in new window',
        'asset.no-links' : 'No links available',
        'asset.warrantyspare' : 'Warranty and spare parts',
        'asset.free-text' : 'Free text search',
        'asset.month' : 'Month',
        'asset.in-use' : 'In use',
        'asset.product' : 'Product',
        'asset.details' : 'Details',
        'asset.as-active' : 'Set as active',
        'asset.as-inactive' : 'Set as inactive',
        'asset.as-inactive-sure' : 'Are you sure you want to set this asset as inactive? Information about user will be deleted.',
        'asset.as-active-sure' : 'Inser name of user below',
        'asset.cost-center' : 'Cost center',
        'asset.installation-address' : 'Installation address',
        'asset.order' : 'Order',
        'asset.price' : 'Price',
        'asset.invoice-no' : 'Invoice number',
        'asset.invoice-date' : 'Invoice date',
        'asset.delivery-date' : 'Delivery date',
        'asset.delivery-address' : 'Delivery address',
        'asset.sub-supplier' : 'Sub supplier',
        'asset.reference' : 'Reference',
        'asset.inventoried-at' : 'Last inventory',
        'asset.manual-inventory' : 'Manual inventory',
        'asset.additional-info' : 'Additional info',
        'asset.savings' : 'The CO2e savings for this product it you choose to send it for reuse instead of recycling',
        'asset.savings-kg' : 'kg CO2e-eq',
        'asset.life-cycle' : 'Life cycle data',
        'asset.potential-savings' : 'Savings',
        'asset.no-env-data' : 'This asset is connected to any category and has therefore no life cycle data',
        'asset.add' : 'Add new assets',
        'asset.use-icecat' : 'Use productinformation from the Icecat database',
        'asset.dont-use-icecat' : 'Manual input',
        'asset.monthly' : 'Montlhy cost (SEK)',
        'asset.monthly-price' : 'Montlhy cost/Price',
        'asset.start-date' : 'Start date',
        'asset.status-rent': 'Rented',
        'asset.status-lease': 'Leased',
        'asset.status-purchased': 'Purchased',
        'asset.update-info' : 'Update information',
        'asset.active' : 'Active',
        'asset.inactive' : 'Inactive',
        'asset.contract/order' : 'Contract/Order',
        'asset.purchased' : 'Purchased',
        'asset.rented' : 'Rented',
        'asset.warranty-support' : 'Warranty and support',
        'assets.stats': 'Statistics',
        'asset.delete' : 'Delete asset',
        'assets.delete' : 'Delete assets',
        'assets.inventory' : 'Inventory',
        'asset.delete-sure' : 'Are you sure you want to delete this asset?',
        'asset.eol' : 'Estimated EOL',
        'asset.multi-search' : 'Serial numbers',
        'asset.multi-placeholder' : 'Use word-wrapping or comma to separate serial numbers',
        'asset.imported-success': 'assets imported',
        'asset.new' : 'New',
        'asset.updated' : 'Updated',
        'asset.invalid' : 'Invalid',
        'asset.imported-assets' : 'Imported assets',
        'asset.edit' : 'Edit asset',
        'asset.created' : 'Created',
        'asset.no-rows' : 'No rows to show',
        'asset.web-not-available' : 'Web url not available',
        'asset.technical-pdf-not-available' : 'PDF not available',
        'asset.icecat-info-update' : 'The product information above is fetched from the Icecat database and can not be edited manually.',
        'asset.no-category' : 'No category',
        'asset.no-description' : 'No description',
        'asset.no-title' : 'No title',
        'asset.no-ean' : 'No EAN',
        'asset.no-supplier' : 'No supplier',
        'asset.no-supplier-code' : 'No supplier code',
        'asset.add-info' : 'You can add assets manually here by filling in owner, product and details. You can add multiple serialnumbers by separating them with commas.',
        'asset.blancco-check-no-res' : 'No Blancco report found',
        'asset.add-new-product' : 'Can\'t find what you are looking for? Add new product',
        'asset.weight' : 'Weight',
        'asset.icecat-fetch-info' : 'Your asset\'s product information is retrieved from Icecat\'s product database. If no information can be fetched, you can fill in the fields manually. Search either for EAN code or for manufacturer together with product code..',
        'asset.download-file' : 'Download example file',
        'asset.no-product-code' : 'Product code not available',
        'asset.owner' : 'Owner',
        'asset.select-product': 'Search for product in product library',
        'asset.manual-info' : 'Manual product info',
        'asset.purchased-info' : 'Purchased info',
        'asset.back' : 'Back to assets',
        'asset.select-other-product' : 'Select other product',
        'asset.first-customer' : 'You have to create a customer in order to upload assets.',
        'asset.create-customer-permission' : 'You don\'t have permission to create new customers in the system. Speak with your admin.',
        'asset.create-as-purchased' : 'This asset will be uploaded as an purchased asset',
        'asset.category-report' : 'Category in report',
        'asset.no-eol' : 'No EOL assigned',
        'asset.no-category-info' : 'This asset is not connected to a category and life cycle data can not be displayed.',
        'asset.not-in-use' : 'Not in use',
        'asset.category-no-eol' : 'This product category does not have any EOL data available',
        'asset.connected-to-contract' : 'You can\'t change the type of an asset when it\'s connected to a contract. If you want to change it to type purchased you need to first remove it from the contract',
        'asset.report-explanation' : '(Source: IVL Svenska Miljöinstitutet)',
        'asset.fmv' : 'EMV',
        'asset.specs' : 'Specs',
        'asset.process-model' : 'Process model',
        'asset.emv': 'Estimated market value',
        'asset.warranty-check' : 'Warranty check',
        'asset.log' : 'Asset log',
        'asset.warranty-end': 'Warranty end date',
        'asset.purchased-at': 'Purchased at',
        'asset.all': 'All assets',
        'asset.status-handle' : 'Handle statuses',
        'asset.type' : 'Type',
        'asset.statuses' : 'Asset statuses',
        'asset.drop-csv' : 'Drop or select your CSV file here',
        'asset.handle-assets-on-contract' : 'Handle assets on contract',
        'asset.history': 'Asset history',
        'asset.asset-log' : 'Logs',
        'asset.assets-deleted' : 'Deleted assets',
        'asset.purchase-date': 'Purchase date',
        'asset.time' : 'Time',
        'asset.take-stock' : 'Take stock',
        'asset.status-connected' : 'Number of assets with this status in the system',
        'asset.status-own' : 'You can only change the Global status',
        'asset.no-product-number' : 'No, product number',
        'asset.serialnumber' : 'Serial number',
        'asset.serialnumbers' : 'Serial numbers',
        'asset.single-serialnumbers' : 'Single serial number',
        'asset.multiple-serialnumbers' : 'Multiple serial numbers',
        'no-warranty' : 'Missing',

        'azure.need-to-sign-in' : 'You need to sign in with your Microsoft account in order to fetch this information',


        'Asset' : 'Asset',
        'asset.asset' : 'Asset',
        'assetstatus.assetstatus' : 'Asset status',


        'billing' : 'Billing',
        'details' : 'Details',
        'period' : 'Period',
        'ecosystem-customer' : 'Paying customer',
        'Statuses' : 'Statuses',
        'nav.assets-api' : 'Assets API',
        'csv-download' : 'Download CSV',
        'required' : 'Required',

        'blancco.list' : 'List Blancco reports',

        'bank.name' : 'Name',
        'bank.full-name' : 'Full name',
        'bank.updated' : 'Last updated',
        'bank.create' : 'Add new bank',
        'bank.banks' : 'Banks',
        'bank.bank' : 'Bank',
        'bank.delete' : 'Delete bank',
        'bank.delete-sure' : 'Are you sure that you want to delete this bank?',
        'bank.edit' : 'Edit bank info',
        'bank.invoice-fee' : 'Invoice fee',
        'bank.invoice-fee-profit' : 'Invoice fee profit',
        'bank.arrangement-fee' : 'Arrangment fee',
        'bank.invoice-arrangement-profit' : 'Arrangement fee profit',
        'bank.delayed-rent-profit' : 'Delayed rent profit',
        'bank.extension-fee-profit' : 'Extension fee profit',
        'bank.insurance-fee' : 'Insurance fee',
        'bank.insurance-fee-profit' : 'Insurance fee profit',
        'bank.go-back' : 'Go back',


        'blancco.info' : 'This is a testing tool for fetching data from the Blancco-API.',
        'blancco.search' : 'Perform a search with the serial number of the asset; e.g ',
        'blancco.or' : 'or',
        'blancco.search-help' : 'Note! The system can search for multiple assets, this testing tool can only handle one serial number at the time.',
        'blancco.PDF' : 'Will generate a PDF-file and open it. Otherwise the response will be in raw data.',
        'blancco.result' : 'The result is displayed as raw data in XML-format.',
        'blancco.back' : 'Go back and perform a new search.',


        'button.move-assets' : 'Move {assets} assets to selected contract',
        'button.assets-move' : 'Move assets',
        'button.move-assets-text' : 'The selected assets will get moved to the selected contract',

        'button.remove-assets' : 'Remove {assets} assets from this contract',
        'button.assets-remove' : 'Remove {assets} from contract',
        'button.assets-remove-text' : 'These assets will not belong to this contract any more.',

        'button.add-assets' : 'Connect {assets} assets to this contract',
        'button.assets-add' : 'Connect assets',
        'button.add-assets-text' : 'The selected assets will belong to this contract.',

        'button.delete-assets' : 'Delete {assets} assets',
        'button.assets-delete' : 'Delete assets',
        'button.assets-delete-text' : 'Are you sure that you want to delete these assets from the system?',

        'button.inventory-assets' : 'Take stock of {assets} assets',
        'button.assets-inventory' : 'Take stock of these assets',
        'button.assets-inventory-text' : 'These assets will get updated with date and time for this action.',

        'button.actions' : 'Select action for {assets} assets',



        'close' : 'Close',
        'cancel' : 'Cancel',
        'inventory' : 'Inventory',
        'delete' : 'Delete',
        'export-csv': 'Export to CSV',
        'fetching' : 'Fetching info',
        'clear-form' : 'Clear form',
        'select-months' : 'Select months',
        'read' : 'Read',
        'handle' : 'Handle',
        'no-available-data' : 'No data available',
        'no-category' : 'No category',
        'no-title' : 'No title',
        'no-description' : 'No description',
        'created' : 'Created',
        'upload-image' : 'Upload product image',
        'select-filters' : 'Filters',
        'select-actions' : 'Actions',
        'select-assets' : 'Select assets',
        'Key Account Managers' : 'Key Account Managers',

        'size-per-page' : 'Size per page',
        'select-columns' : 'Columns',

        'months' : '{values} months',
        'years' : '{years} years',

        'category.has-eol': 'With EOL',
        'category.no-eol': 'Without EOL',
        'archived': 'Archived',

        'morecoreport.morecoreport' : 'Moreco reports',
        'type' : 'Type',


        'contract.id' : 'Contract id',
        'contract.add-assets' : 'Add assets to contract',
        'contract.substart' : 'Sub starts',
        'contract.contracts' : 'Contracts',
        'contract.create' : 'Create contract',
        'contract.hide' : 'Hide contracts',
        'contract.display' : 'Display contracts',
        'contract.contract' : 'Contract',
        'contract.title' : 'Title',
        'contract.end' : 'End date',
        'contract.expiration' : 'Upon expiration',
        'contract.agreement-no' : 'Agreement number',
        'contract.total' : 'Total',
        'contract.created' : 'Created',
        'contract.list' : 'List',
        'contract.archived' : 'Archived',
        'contract.search' : 'Search contracts',
        'contract.log' : 'Contract log',
        'contract.logs' : 'Logs',
        'contract.log-time' : 'Time',
        'contract.log-comment' : 'Activity/Comment',
        'contract.deleted' : 'Contracted deleted',
        'contract.delete' : 'Delete contract',
        'contract.delete-help' : 'Are you sure you want to delete this contract?',
        'contract.delete-sure' : 'Are you sure you want to delete this contract?',
        'contract.edit-expiration' : 'Edit expiration options - contract',
        'contract.upon-expiration' : 'Upon expiration of contract',
        'contract.by' : 'By',
        'contract.type' : 'Type',
        'contract.length' : 'Length',
        'contract.insurance' : 'Insurance',
        'contract.setup-fee' : 'Set up fee',
        'contract.residual-value' : 'Residual value',
        'contract.object-value' : 'Object value',
        'contract.bank' : 'Bank/financier',
        'contract.customer-details' : 'Customer details',
        'contract.extension' : 'Extension history',
        'contract.start' : 'Start date',
        'contract.monthly-cost' : 'Monthly cost',
        'contract.requested-by' : 'Requested by',
        'contract.update' : 'Update',
        'contract.verify' : 'Verify',
        'contract.rent' : 'Renting',
        'contract.lease' : 'Leasing',
        'contract-extension-start': 'Extension start date',
        'contract.end-date' : 'End date',
        'contract.selected-by': 'Expiration option selected by',
        'contract.confirmed-by': 'Confirmed by',
        'contract.expiration-extend' : 'The contract will get automatically extended with 12 months upon the expiration date if no other option is selected. You can select how many months you wish to extend the contract with.',
        'contract.expiration-terminate' : 'The contract will be terminated on the end date and the products will be returned. If you wish to end the contract before {date} please select a new date below.',
        'contract.buy-out-all' : 'Yes, I want a buy out quote for all of the assets on this contract',
        'contract.select-assets' : 'Select assets. (Link to another page)',
        'contract.buy-all' : 'Do you wish to buy all ({assets}) assets on this contract?',
        'contract.no-assets' : 'No available assets to select on this contract',
        'contract.terminated' : 'Terminated',
        'contract.no-extensions' : 'No extensions to display on this contract',
        'contract.no-log' : 'No history available for this contract',
        'contract.archive' : 'Archive',
        'contract.start-date': 'Start date',
        'contract.special-conditions' : 'Special conditions',
        'contract.select-monthly' : 'Select monthly cost',
        'contract.down-payment' : 'Down payment',
        'contract.surcharge-value' : 'Surcharge value',
        'contract.payment-period' : 'Payment period',
        'contract.bank/financier' : 'Bank/financier',
        'contract.include-insurance' : 'Include insurance',
        'contract.select-end-date' : 'Select end date',
        'contract.use-card' : 'Use available data from the customer card',
        'contract.customer-info' : 'Customer information',
        'contract.delivery-address' : 'Delivery address',
        'contract.add-delivery-address' :'Add other delivery address',
        'contract.delivery-zip' : 'Delivery zip',
        'contract.delivery-city' : 'Delivery city',
        'contract.comment' : 'Comment',
        'contract.option-extension' : 'Extension',
        'contract.option-surcharge' : 'Surcharge',
        'contract.option-termination' : 'Termination',
        'contract.product' : 'Product',
        'contract.product-price' : 'Price',
        'contract.product-amount' : 'Amount',
        'contract.add-product' : 'Add product',
        'contract.edit' : 'Edit contract',
        'contract.change-montly-cost' : 'Change monthly cost',
        'contract.select-sub-start-date' : 'Select sub start date',
        'contract.sub-start-date' : 'Sub start date',
        'contract.framework' : 'Framework',
        'contract.direct' : 'Direct',
        'contract.residual-value-guarantor' : 'Residual value guarantee',
        'contract.import-assets' : 'Import assets to this contract',
        'contract.import-with-csv' : 'Import with CSV file',
        'contract.select-from-list': 'Add assets from list',
        'contract.remove-from-contract' : 'Remove assets from contract',
        'contract.handle-assets' : 'Handle assets',
        'contract.supplier' : 'Supplier',
        'contract.description' : 'Description',
        'contract.active' : 'Active contracts',
        'contract.inactive' : 'Inactive',
        'contract.archive-help' : 'When archiving a contract all assets connected to this contract will be deleted. If you wish to keep these assets in the system, remove them from this contract or connect them to another contract first.',
        'contract.move-to-other-contract' : 'Move assets to other contract',
        'contract.create-copy' : 'Create a Copy',
        'contract.create-copy-text' : 'You can edit the fields below. All information except of the assets will be saved as a new contract.',
        'contract.is-active' : 'Active',
        'contract.is-inactive' : 'Contract will be listed as inactive until changed to active',
        'contract.extend' : 'Extend contract',
        'contract.extend-help' : 'Select the new end date for the contract. Previous dates will be listed in contract history.',
        'contract.new-end-date' : 'New end date',
        'contract.history' : 'History',
        'contract.back-to-contract' : 'Back to Contract',
        'contract.show-extensions' : 'Show extensions',
        'contract.create-substart' : 'Create sub start',
        'contract.create-substart-text' : 'This sub start will be linked to the framework contract below. Assets connected to this sub start will also be linked to the main framework contract.',
        'contract.other-substarts' : 'Other sub starts',
        'contract.sub-starts' : 'Sub starts',
        'direct' : 'Direct',
        'framework' : 'Framework',
        'substart' : 'Sub start',
        'active' : 'Active',
        'inactive' : 'Inactive',
        'rent' : 'Rented',
        'quarterly' : 'Quarterly',
        'all' : 'All',
        'history': 'History',


        'contact.mail' : 'Mail',
        'contact.address' : 'Address',
        'contact.phone' : 'Phone',
        'contact.city' : 'City',
        'contact.zip' : 'Zip',

        'complience.title' : 'Reporting that strengthens your company\'s environmental profile and shows compliance to GDPR.',
        'complience.subheading' : 'With our transparent reporting, we help to prove compliance with the requirements of GDPR while providing you with supporting evidence of your environmental commitment.',
        'complience.list-1' : 'What is the environmental impact of your IT equipment',
        'complience.list-2' : 'Have you ever wondered on how much environmental savings you can achieve by promoting reuse instead of recycling? ',
        'complience.list-3' : 'Can you prove that your old computers were erased according to the GDPR requirements?',
        'complience.text-1' : 'We give you access to all our reports and information directly in the customer portal where you may also download these reports to show colleagues and customers or attach to your company\'s reporting and accounting.',
        'complience.text-2' : 'There are over 20 different reports that show compliance from start to finish. These reports form a flow that can be attached to everything from ISO work to external IT auditing.',
        'complience.list-title' : 'The most common reports include:',
        'complience.list-4' : 'Receiving report',
        'complience.list-5' : 'Inventory report units',
        'complience.list-6' : 'Inventory report storage media',
        'complience.list-7' : 'Category report, weight, number and disposition',
        'complience.list-8' : 'Certificate of destruction',
        'complience.list-9' : 'Certificate of recycling',
        'complience.list-10' : 'Certificate of sanitization',
        'complience.list-11' : 'Environmental impact and savings report',
        'complience.list-12' : 'Financial report',
        'complience.contact' : 'Curious about what these reports look like and how can you use them to strengthen your brand? ',


        'customer.margin' : 'Margin',
        'customer.customers' : 'Customers',
        'customer.create' : 'Create customer',
        'customer.hide' : 'Hide customers',
        'customer.display' : 'Display customers',
        'customer.customer' : 'Customer',
        'customer.card' : 'Customer card',
        'customer.edit' : 'Edit customer',
        'customer.update' : 'Update customer',
        'customer.list' : 'List Customers',
        'customer.join' : 'Joining date',
        'customer.select' : 'Select customer',
        'customer.delete' : 'Delete Customer',
        'customer.delete-sure' : 'Are you sure that you want to delete this customer? All assets and other information connected to this customer will be lost.',
        'customer.no-rows' : 'No customers to display',
        'customer.go-back' : 'Go back to customers',
        'customer.new' : 'New Customer',
        'customer.nr' : 'Customer number',

        'dashboard.contracts-due-soon': 'Contracts with end date within six months',
        'dashboard.warranty-opportunity' : 'Warranty information',
        'dashboard.assets-by-reseller' : 'Assets by reseller',
        'dashboard.hello': 'Welcome',
        'dashboard.close' : 'Close',
        'dashboard.selext-expiration' : 'Select expiration',
        'dashboard.import-assets' : 'Import assets',
        'dashboard.display-contracts' : 'Display contracts',
        'dashboard.credit-checks' : 'Credit checks',
        'dashboard.buy-outs' : 'Request buy outs',
        'dashboard.show-team' : 'Show users',
        'dashboard.hide-team' : 'Hide users',
        'dashboard.contracts' : 'Contracts',
        'dashboard.contract' : 'Contract',
        'dashboard.new-contracts' : '{value} new contracts this month',
        'dashboard.quotes' : 'Quotes',
        'dashboard.quote' : 'Quote',
        'dashboard.new-quotes' : '{value} new quotes this month',
        'dashboard.customers' : 'Customers',
        'dashboard.customer' : 'Customer',
        'dashboard.reseller' : 'Reseller',
        'dashboard.new-customer' : '{value} new customers this month',
        'dashboard.assets' : 'Assets by customer',
        'dashboard.customer-assets' : 'Your customers\' assets',
        'dashboard.show-customers' : 'Show customers',
        'dashboard.hide-customers' : 'Hide customers',
        'dashboard.contract-log' : 'Contract log',
        'dashboard.none' : 'None',
        'dashboard.new' : 'New',
        'dashboard.go-profile' : 'To profile',
        'dashbard.handle' : 'Handle contracts and quotes',
        'dashboard.reseller-expire-3' : 'Less than 3 months',
        'dashboard.reseller-expire-6' : 'Due soon',
        'dashboard.reseller-outside-warranty' : 'Expired',
        'dashboard.reseller-fair-market-value' : 'Opportunities',
        'dashboard.reseller-present-value' : 'Outside of warranty',
        'dashboard.reseller-near' : 'Less than 3 months',
        'dashboard.reseller-long' : 'Less than 6 months',
        'dashboard.reseller-resale' : 'Possible new resale value',
        'dashboard.reseller-warranty-status' : 'Warranty status',
        'dashboard.handle' : 'Handle contracts',
        'dashboard.fair-market-value' : 'Estimated fair market value',
        'dashboard.new-resale-value' : 'Estimated new resale value',
        'dashboard.reseller-unknown': 'Unknown',
        'dashboard.assets-by-customer' : 'Assets by customer',
        'dashboard.warranty' : 'Warranty for assets',
        'dashboard.emission-data' : 'Emission data',
        'dashboard.reseller-inside' : 'Covered',
        'dashboard.opportunity-graph' : 'Opportunity potential by Customer',
        'dashboard.warranty-status' : 'Warranty status on assets',
        'dashboard.potential-saving': 'Potential environmental savings',
        'dashboard.opportunity' : 'Opportunity potential',


        'distribution.title' : 'Circular distribution of quality assured products and components from the leading manufacturers',
        'distribution.sub-title' : '100% circular means that it is designed for reuse from the very beginning and where the products are allowed to change owners without restrictions and with the possibility for any user to change faulty components themselves. ',
        'distribution.100' : 'Choosing refurbished products is always 100% circular!',
        'distribution.100-text' : 'Our refurbisheing program gives you access to the same proven technology used by the major OEMs but at a lower price. The products and components we process are thoroughly quality tested, often through several standalone test methods and testing programs and we fully guarantee everything we sell which means secure warranties up to 3 years.',
        'distribution.100-text-2' : 'We are aimed at resellers, infrastructure specialists and data centers that demand the highest quality, good accessibility and who wish to extend their commitment to a sustainable and circular model.',
        'distribution.table-title' : 'Buying from us provides significant environmental sustainability benefits, we want you to expect more from the hardware you buy, requiring it to always be 100% circular.',
        'distribution.table-subtitle' : 'How to choose 100% circular components for an extended sustainability commitment',
        'distribution.table-1-title' : 'Processors & Memory Modules ',
        'distribution.table-1-text-1' : 'Always choose originals when you change or upgrade your hardware. This way you save money and contribute 100% for a more circular way. By original we mean the original manufacturer of the component such as Intel for processors and Hynix or Samsung for memory modules.',
        'distribution.table-1-text-2' : 'We also stock components from the major OEMs, such as HP, IBM, Lenovo and Dell.',
        'distribution.table-2-title' : 'Hard Drives',
        'distribution.table-2-text-1' : 'With the same performance, same quality and with trusted warranties at up to 70% lower price against new, there is no reason to choose anything other than 100% circular.',
        'distribution.table-2-text-2' : 'With our erasure test and refurbishing process of hard drives, we can ensure the highest possible safety and quality regardless of disk type or disk size..',
        'distribution.table-3-title' : 'Circular Components',
        'distribution.table-3-text-1' : 'There are almost an endless supply of components available where you may save even more on the environment and your companys budget. By choosing refurbished network cards, HBAs, GPUs, GBICs, SFPs and much more you gain even more advantages.',
        'distribution.table-3-text-2' : 'The only difference between a refurbished 10GbE network card and a new one is the price, up to 70% lower price.',
        'distribution.1' : 'We always have stock of quality tested DDR3 and DDR4 memories',
        'distribution.2' : 'We always have stock of quality tested Intel v3 and v4 processors',
        'distribution.3' : 'We carry a wide range of different manufacturers, including OEMs',
        'distribution.4' : 'We always offer compatibility checks',
        'distribution.5' : 'Large supply (1000+) of SAS, SATA, SSD in stock',
        'distribution.6' : 'Sizes varying from 146GB to 8TB',
        'distribution.7' : 'Choose from the major manufacturers and OEMs',
        'distribution.8' : 'Always tested and delivered with health test protocol',
        'distribution.9' : 'PSUs, HBAs, graphics- and expansion cards',
        'distribution.10' : 'All types of cabling',
        'distribution.11' : 'Network components for servers and switches such as SFPs and more.',
        'distribution.form' : 'Do you want to join our network of channel partners and take advantage of our offers? Just let us know how we can contact you!',

        'date' : 'Date',
        'edit' : 'Edit',
        'update' : 'Update',
        'archive': 'Archive',
        'hide' : 'Hide',
        'display' : 'Show',
        'create' : 'Create',
        'upload' : 'Upload',
        'uploading': 'Uploading',
        'include-all' : 'Include all',
        'include-all-categories' : 'Include all categories',
        'include-all-customers' : 'Include all customers',
        'include-all-resellers' : 'Include all resellers',
        'include-all-suppliers' : 'Include all suppliers',

        'info.inventoried-at' : 'Mass update last inventory of asset',
        'info.serialnumber' : 'Unique in system',
        'info.product-id' : 'Connect the right product information to your asset by adding the right product ID.',
        'info.supplier' : 'Obligatory if no Product ID is selected. Name of supplier. e.g HP, Apple',
        'info.supplier-code' : 'Obligatory if no Product ID is selected. The supplier code. e.g 6EB98EA',
        'info.customer-id' : 'Customer ID is used to connect the asset to the right customer. List of IDs can be found below.',
        'info.contract-id' : 'If you want to import assets and connect them to existing contract in the system',
        'info.type' : 'Can have 2 values. [ purchased, rented ]. If contract ID is present the type will default to rented',
        'info.in-use' : '1 to set to true, 0 to set to false. This field can be used in case you dont want to specify the user.',
        'info.user' : 'If you want to save the information about the user',
        'info.delivery' : 'The delivery date is used for all life cycle calculations in the system',
        'info.computer-name' : 'The computer name',
        'info.mac-address' : 'The Mac address',
        'info.monthly-cost' : 'The monthly cost will be summed up to the contract they are connected to. Only digits, e.g 3452',
        'info.order': 'Field for order number',
        'info.reference': 'For extra information',
        'info.warranty-end': 'A search for warranty information will be made when asset the asset is created. This field is for manually added warranty information.',
        'info.price' : 'Only digits, e.g 3452',
        'info-not-avaiable' : 'Not available',
        'link': 'Link',
        'editable-info' : 'Editable info',
        'no-info' : 'No info',
        'no-auth' : 'Not authorized',
        'no-auth-description' : 'Your admin can help you with your permissions',
        'date-missing' : 'Date missing',
        'info.format-date' : 'Date format YYYY-MM-DD',

        'email.sent' : 'Request for new password was successfully sent',
        'email.something-wrong' : 'Something went wrong, please try again',


        'erasure.title' : 'Fully certified data erasure and data destruction',
        'erasure.subheading' : 'It is extremely important for storage media to be erased correctly before IT assets are divested, reused or sent for recycling.',
        'erasure.general' : 'About data erasure',
        'erasure.general-1' : 'Exposure of confidential and sensitive information has become an increasingly common phenomenon. In the media, every week, stories are written about noteworthy cases involving loss of sensitive data. In today\'s information society, our information assets pose a significant risk due to the large amount of confidential information stored on them.',
        'erasure.general-2' : 'THE DEFINITION OF SENSITIVE OR CONFIDENTIAL INFORMATION IS INFORMATION PROTECTED FROM UNLAWFUL DISTRIBUTION.',
        'erasure.general-3' : 'A simpler description or rather rule of thumb is: All information that is not publicly available should be considered confidential or sensitive. All companies invest in several different solutions to protect themselves against unauthorized access to stored information. Most companies have antivirus systems, firewalls, VPN solutions, Intrusion Detection System (IDS), and more. How many companies then have the corresponding information protection when the systems are phased out?',
        'erasure.general-4' : 'Has your company invested in overwrite software, demagnetization equipment or a metal chew? Probably not, the focus is on the core business and quality of delivery to your customers. Unfortunately it is not enough to drill three holes in the hard drive, the only thing that has been achieved is to render it unusable, information remains and with today\'s technology and resources most of the information can be recreated.',
        'erasure.general-5' : 'It is extremely important that storage media be erased correctly before IT assets are divested, reused or sent for recycling. Companies and their employees are required by law (1990: 409) to ensure the safe handling of sensitive information. Unfortunately, today it is not enough just to know for yourself that the information is erased correctly. In order to avoid facing any legal sanctions that may result in non-compliance, companies must be able to prove that the information is properly destroyed according to an approved standard.',
        'erasure.experts' : 'Moreco are experts in data erasure and destruction',
        'erasure.experts-text' : 'We offer one hour of free consultation including needs analysis. The needs analysis helps you to develop a decision basis and answer if you need to change something to increase safety. However, our paradigm is to carry out data destruction for you or data erasure at your place, something we are largely alone in being able to do. We can also help you to develop a reliable data destruction policy.',

        'read-more' : 'Read more',
        'faq.general' : 'General Questions',
        'faq.security' : 'Ecosystem Security',
        'faq.privacy' : 'Privacy Policy',
        'faq.support' : 'Support',
        'faq.comming-soon' : 'Mobile version is comming soon. You can visit this page from desktop',
        'faq.technical-support' : 'Are you experiencing technical issues or cant find what you are looking for? Please contact us for support!',
        'faq.email-us' : 'Send us an email',
        'faq.assets-how' : 'How do I create new assets?',
        'faq.assets-1' : 'Assets can be created in 3 different ways. By uploading a CSV-file, manually added through the create asset form and through our API.',
        'faq.assets-import-title' : 'I have many assets that I would like to upload with a file',
        'faq.assets-import-text' : 'You can upload a CSV-file if you want to create multiple assets in the system. Download the example file to see which information that can be manually created.',
        'faq.assets-import-link' : 'Go to import',
        'faq.assets-manual-title' : 'I just want to add one asset and I dont have all product information available',
        'faq.assets-manual-text' : 'On the page create new asset you can add one or multiple assets of the same product manually. Search for your product in our product library. If you cant find what you are looking for, you can make a search in the Icecat product catalog. When you have selected your product you just insert your serial numbers and fill in the information you want to store.',
        'faq.assets-manual-link' : 'Go to create form',
        'faq.assets-api-title' : 'I want to create an integration and handle my assets through the API',
        'faq.assets-api-text' : 'Before you start using our API you need to create you private API-key. You can do that on your profile page.',
        'faq.assets-api-link' : 'Go to my profile',
        'faq.assets-serial-title' : 'Why do I need to fill in the serial number on my assets?',
        'faq.assets-serial-text' : 'We use the serial number to fetch information about your asset. We look for the available warranty information and other information that is used in calculations, like the expected end of life.',
        'faq.assets-eol-title' : 'What is EOL?',
        'faq.assets-eol-text' : 'EOL stands for end of life and is an estimation about the products lifespan. We calculate the expected life of every product with the purchase date and the category the product belongs too. If we cant find the purchase date the delivery date of the asset will be used, so don\'t forget to register that information in order to keep track of your aging assets.',
        
        'form.select-user' : 'Select User',
        'form.select-role' : 'Select role',
        'form.select-lotnumber' : 'Select lot number',
        'form.select-year' : 'Select year',
        'form.enter-pdf-url' : 'Enter PDF url',
        'form.enter-ean' : 'Enter EAN, 13 digits',
        'form.enter-market-value' : 'Enter market value',
        'form.enter-product-url' : 'Enter product url',
        'form.enter-product-code' : 'Enter product code',
        'form.enter-weight' : 'Enter weight',
        'form.supplier-code' : 'Supplier code',
        'form.enter-computer-name' : 'Enter computer name',
        'form.enter-mac-address' : 'Enter mac address',
        'form.enter-imei-number' : 'Enter IMEI Number',
        'form.enter-external-number': 'External customer id',
        'form.select-a-product': 'Select product',
        "form.enter-additional-info" : 'Enter additional info',
        'form.fetch' : 'Fetch info',
        'form.logo' : 'Upload logotype',
        'form.photo' : 'Profile picture',
        'form.drop' : 'Drop file here or click to upload.',
        'form.contact' : 'Contact info',
        'form.org-no' : 'Org. no',
        'form.name' : 'Name',
        'form.connect-reseller': 'Conneced to reseller',
        'form.comments': 'Comments',
        'form.avatar' : 'Upload profile picture',
        'form.call-me' : 'Call me',
        'form.email-me' : 'Email me',
        'form.leave' : 'Leave blank',
        'form.select-date' : 'Select date',
        'form.account' : 'Account',
        'form.categories' : 'Categories',
        'form.select-customer' : 'Select Customer',
        'form.select-contract' : 'Not connected to contract',
        'form.select-product' : 'Select your product or add a new below',
        'form.select-category' : 'Select category',
        'form.select-supplier' : 'Select supplier',
        'form.select-reseller' : 'Select reseller',
        'form.select-status' : 'Select status',
        'form.select-asset-type' : 'Select type',
        'form.select-reseller-first' : 'Select reseller first',
        'form.enter-customer-name': 'Customer name',
        'form.enter-customer-name-message': 'Enter customer name',
        'form.enter-orgno': 'Org number',
        'form.enter-orgno-message': 'Enter Org number',
        'form.customer-city' : 'Customer city',
        'form.enter-customer-city' : 'Enter the customer name',
        'form.customer-phone' : 'Customer phone',
        'form.enter-customer-phone' : 'Enter the customer phone',
        'form.customer-zip' : 'Customer Zip',
        'form.enter-customer-zip' : 'Enter the customer zip',
        'form.customer-address' : 'Customer address',
        'form.enter-customer-address' : 'Enter the customer address',
        'form.customer-email' : 'Customer Email',
        'form.enter-customer-email' : 'Enter the customer email',
        'form.enter-comment' : 'Enter comment',
        'form.enter-title' : 'Title',
        'form.enter-title-message' : 'Give the contract a title',
        'form.enter-agreement-no' : 'Agreement number',
        'form.enter-agreement-no-message' : 'Enter the agreement number',
        'form.select-type' : 'Select type of contract',
        'form.select-length' : 'Select number of months',
        'form.select-financier' : 'Select financier',
        'form.surcharge' : 'Surcharge value',
        'form.enter-surcharge' : 'Enter value',
        'form.residual-value' : 'Residual value',
        'form.enter-residual-value' : 'Enter value',
        'form.down-payment' : 'Down payment',
        'form.enter-down-payment' : 'Enter value',
        'form.monthly-cost' : 'Monthly cost',
        'form.enter-monthly-cost' : 'Enter monthly cost',
        'form.special-conditions' : 'Enter special conditions',
        'form.select-upon-expiration' : 'Select upon expiration',
        'form.delivery-address' : 'Delivery address',
        'form.delivery-zip' : 'Delivery Zip',
        'form.delivery-city' : 'Delivery City',
        'form.full-name': 'Full name',
        'form.enter-supplier' : 'Enter supplier/manufacturer',
        'form.enter-supplier-code' : 'Enter supplier code',
        'form.enter-product-number' : 'Enter product number',
        'form.enter-product-description' : 'Enter product description',
        'form.enter-cost-center' : 'Enter const center',
        'form.enter-serial-numbers' : 'Enter serial numbers. Seperate them with comma',
        'form.enter-user' : 'Enter user of asset',
        'form.enter-installation-address' : 'Enter installation address',
        'form.enter-description': 'Enter description',
        'form.enter-invoice-no' : 'Enter invoice number',
        'form.enter-order-no' : 'Enter order number',
        'form.enter-reference' : 'Enter reference',
        'form.enter-price' : 'Enter price',
        'form.enter-org' : 'Enter organisation number',
        'form.enter-name' : 'Enter name',
        'form.enter-email' : 'Enter email',
        'form.enter-address' : 'Enter adress',
        'form.enter-zip' : 'Enter zip',
        'form.enter-phone' : 'Enter phone',
        'form.enter-city' : 'Enter city',
        'form.enter-comments' : 'Other comments',
        'form.enter-delivery-address' : 'Enter delivery address',
        'form.enter-delivery-zip' : 'Enter delivery zip',
        'form.enter-delivery-city' : 'Enter delivery city',
        'form.enter-password' : 'Enter new password',
        'form.confirm-password' : 'Confirm new password',
        'form.confirm-password-error' : 'The passwords does not match',
        'form.password': 'New password - min 10 characters, upper and lowercase letters, one or more digits, and a special character',
        'form.enter-your-password' : 'Enter your password',
        'form.six-digit-code' : '6 digit code',
        'form.enter-seix-digit-code' : 'Enter the 6 digit code on your device',
        'form.old-password' : 'Old password',
        'form.new-password' : 'New password',
        'form.enter-old-password' : 'Enter your old password',
        'form.enter-new-password' : 'Enter your new password',
        'form.contact-info' : 'Contact info',
        'form.enter-sub-supplier' : 'Enter sub supplier',
        'form.no-status' : 'No status',
        'form.enter-contract-supplier' : 'Enter supplier for this contract',
        'form.enter-residual-value-guarantor' : 'Enter residual value guarantor',
        'form.enter-bank' : 'Bank',
        'form.enter-object-value' : 'Object value',
        'form.enter-special-conditions' : 'Enter special conditions',
        'form.select-start-date-warning' : 'You have to select the start date.',
        'form.select-end-date-warning' : 'You have to select the end date.',
        'form.contracts' : 'Select contract',
        'form.select-delivery-date-warning' : 'The delivery date is obligatory. This date will be used in calculations when the purchase date cant be fetched.',
        'form.no-user-info' : 'Not assigned to anyone',
        'form.eol-include-expired' : 'Include expired assets',
        'form.enter-margin' : 'Enter margin',

        'gdpr.person-title' : 'Processing personal data in Ecosystem',
        'gdpr.sensitive-title' : 'Sensitive personal data in Ecosystems',
        'gdpr.pul-title' : 'Responsible and assistant for tasks in Ecosystems services',
        'gdpr.resp-title' : 'Ecosystem as personal data controller',
        'gdpr.li-title' : 'Basic principles of the GDPR',
        'gdpr.more-title' : 'More information about data protection management',

        'gdpr.title' : 'GDPR and data protection',
        'gdpr.sub-title' : 'GDPR stands for General Data Protection Regulation and is a data protection regulation from the EU which is a law in Sweden to protect the privacy of individuals. You as a customer have a several things that you need to think about regarding the processing of personal data. As a customer, you are responsible for the personal data in Ecosystem. Ecosystem is (Personal Data) Processor.',
        'gdpr.person-1' : 'Personal data can be explained as any information relating to an identified or identifiable individual (also called data subject), whereby an identifiable natural person is a person who can be directly or indirectly identified, in particular with reference to an identifier such as a name, identification number, location information or online identifiers, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of the natural person. Processing of this data means that you carry out an action or combination of actions regarding personal data or sets of personal data, regardless of whether they are performed automatically or not. Examples of processing of personal data are collection, structuring, storage, processing, dissemination or deletionven kallad registrerad), varvid en identifierbar Personal data can be explained as any information relating to an identified or identifiable individual (also called a registered person), whereby an identifiable natural person is a person who can be directly or indirectly identified, in particular with reference to an identifier such as a name, identification number, location information or online identifiers, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of the natural person. Processing of this data means that you carry out an action or combination of actions regarding personal data or sets of personal data, regardless of whether they are performed automatically or not. Examples of processing of personal data are collection, structuring, storage, processing, dissemination or deletion.',
        'gdpr.person-2' : 'As a personal data controller, you need to know what personal data you can collect, why and for how long you should have this data in the ecosystem. Only an organization knows what personal data will be processed in ecosystem services..',
        'gdpr.sensitive-1' : 'There is a special category of personal data that the law addresses and that you as the person responsible for personal data need to pay extra attention to, it is sensitive personal data.',
        'gdpr.sensitive-2' : 'Examples of sensitive personal data are data that reveal ethnic origin, political opinions, religious or philosophical beliefs or data on health and sexual life. Only you as the person responsible for personal data know which sensitive personal data will be processed in Ecosystem services. If, as a result of you, your customers or suppliers\' activities, you will process sensitive personal data, you need to find out what security measures may be required in such processing before such processing begins. Some functions are not recommended for use if you are processing sensitive personal data, which you must find out.',
        'gdpr.sensitive-link' : 'Read more about sensitive information here.',
        'gdpr.pul-1': 'In the control of personal data, there are mainly two roles that you should be aware of and depending on what role you have, there are different areas of responsibility. As a customer, you are the personal data controller in Ecosystem.',
        'gdpr.pul-2': 'The person responsible for personal data (personal data controller) is the one who according to the law has the ultimate responsibility for the processing and determines the purpose and means. The person responsible for personal data shall ensure that the law is complied with, shall inform the persons whose personal data is processed and shall ensure the compliance of the personal data assistant. The Personal Data processor, processes the personal data on behalf of the personal data controller and is responsible for the technical and organizational security measures.',
        'gdpr.pul-3': 'Ecosystem is a personal data processor and takes technical and organizational security measures to make you feel confident that your collected personal data will be processed securely and in accordance with the law.',
        'gdpr.resp-1' : 'All processing of personal data about you as a customer or user, we are responsible for personal data when you use Ecosystems services or contact us.',
        'gdpr.li-1' : 'Legality, correctness and transparency',
        'gdpr.li-2' : 'Purpose limitation',
        'gdpr.li-3' : 'Task minimization',
        'gdpr.li-4' : 'Correctness',
        'gdpr.li-5' : 'Storage minimization',
        'gdpr.li-6' : 'Integrity and confidentiality',
        'gdpr.li-7' : 'Liability',
        'gdpr.read-more' : 'You can read about what the basic principles mean at',
        'gdpr.read-more-link' : 'datainspektionen.se',
        'gdpr.more-1': 'Within each EU Member State, there is a supervisory authority that monitors compliance with the Data Protection Regulation. In Sweden, this authority is called the Data Inspektionen. On their website there is more information and help that you can take part of to find out what you need to do',


        'contract.monthly' : 'Montlhy in advance',
        'contract.quarterly' : 'Quarter in advance',
        'contract.select-payment-period' : 'Select payment period',

        'icecat.info' : 'This is a testing tool for fetching Icecat-data.',
        'icecat.search' : 'Perform a search with the EAN e.g ',
        'icecat.or' : 'or',
        'icecat.search-2' : 'Perform a search with the product code and supplier e.g ',
        'icecat.success' : 'The response will be saved locally for quick access when requested again.',
        'icecat.result' : 'The search result on ',
        'icecat.back' : 'Go back and perform a new search',
        'icecat.no-result' : 'No results',
        'icecat.error' : 'Something went wrong. This is the response from the Icecat-database:',
        'icecat.title' : 'Test data for Icecat',

        'key.key' : 'Key Account Managers',

        'login.back' : 'Back to login',
        'login.welcome-back': 'Welcome back!',
        'login.signin-to-continue' : 'Sign in with your email',
        'login.mail' : 'Email',
        'login.mail-login' : 'Login with your account',
        'login.mail-place' : 'Enter email',
        'login.password': 'Password',
        'login.password-place': 'Enter password',
        'login.login': 'Log in',
        'login.forgot': 'Did you forget your password?',
        'login.dont-have' : 'Don\'t have an account ?',
        'login.sign-up': 'Sign up',
        'login.allready' : 'Already have an account ?',
        'login.register' : 'Register',
        'login.register-text' : 'Register to sign in and start using the Ecosystem',
        'login.username' : 'Username',
        'login.username-place' : 'Enter username',
        'login.by-registering' : 'By registering you agree to the Moreco',
        'login.terms' : 'Terms of use',
        'login.forgot-title' : 'Forgot your password ?',
        'login.forgot-text' : 'Enter your account email.',
        'login.reset' : 'Send reset password link',
        'login.go-back': 'Go back to',
        'login.welcome' : 'Welcome',
        'login.change-password-title' : 'Change password',
        'login.change-password-text' : 'Select your new password',
        'login.new-password' : 'Save new password',
        'login.password-was-updated' : 'Password was succesfully updated!',
        'login.token-expired' : 'Token expired, you need to get a new reset link',
        'login.two-factor-auth' : 'Two factor authentication',
        'login.two-factor-auth-text' : 'Enter the 6 digit code from the application on your device',


        'logistics.title' :'Secure IT logistics means locked and monitored, door to door.',
        'logistics.text1' :'An unbroken supply chain is most important on the day you decommission your used IT equipment. The biggest cost of getting your computer stolen is the information loss you suffer, and any potential penalties associated with GDPR.',
        'logistics.text2' :'Our IT logistics solutions are based on achieving the highest possible security through the implementation of simple logistics routines where we carry out transports in special security cabinets. Moreco offers a subscription service for security cabintes especially designed for IT equipment with fixed or flexible exchange dates. The major advantage of the service is that you always keep the old IT equipment locked in until it can be erased properly.',
        'logistics.title2' :'One point of contact for a safer and more efficient process',
        'logistics.text3' :'Moreco has many years of experience in reversed logistics of IT products and an extensive international network of contacts that ensures that we can deliver the right logistics solutions anywhere in the world. A collaboration with Moreco provides you with benefits as;',
        'logistics.list-1' :'The right experience and resources ',
        'logistics.list-2' :'Proper equipment ',
        'logistics.list-3' :'A single point of contact for a safer and more efficient process',
        'logistics.contact' : 'Secure IT-logistics? We\'ll help you, for more information:',

        'logout' : 'Log out',
        'loading': 'Loading...',
        'lookup.unavailable' : 'Lookup completed without result',
        'lookup.pending' : 'Searching for warranty data, this may take up to a few hours',
        'lookup.complete': 'Lookup completed',

        'nav.assets-list-rented' : 'List rented assets',
        'nav.menu' : 'Menu',
        'nav.dashboard' : 'Dashboard',
        'nav.assets' : 'Assets',
        'nav.assets-history' : 'History',
        'nav.assets-list' : 'List',
        'nav.assets-create' : 'Create new',
        'nav.assets-import' : 'Import',
        'nav.contracts' : 'Contracts',
        'nav.contracts-list': 'List',
        'nav.contracts-create': 'Create new',
        'nav.resellers' : 'Resellers',
        'nav.resellers-list' : 'List',
        'nav.resellers-create' : 'Create new',
        'nav.customers' : 'Customers',
        'nav.customers-list' : 'List',
        'nav.customers-create' : 'Create new',
        'nav.users' : 'Users',
        'nav.users-list' : 'List',
        'nav.users-create' : 'Create new',
        'nav.tools' : 'Tools',
        'nav.bank' : 'Banks',
        'nav.categories' : 'Categories',
        'nav.suppliers' : 'Suppliers',
        'nav.services' : 'Services',
        'nav.soon' : 'Comming soon',
        'nav.erasure' : 'Erasure and shredding',
        'nav.onsite' : 'Decomissioning',
        'nav.logistics' : 'IT logistics',
        'nav.recovery' : 'Value recovery',
        'nav.distribution' : 'Circular distribution',
        'nav.complience' : 'Complience',
        'nav.assets-status' : 'Statuses',
        'nav.handle-kam' : 'Handle Key Account Managers',

        'notifications.notifications' : 'Notifications',
        'notifications.no-2FA' : '2FA is not activated',
        'notifications.no-2FA-text' : 'Increase your accounts security by activating the two factor authentication.',
        'notifications.no-notifications' : 'You don\'t have any new notifications',

        'no' : 'No',
        'editable': 'Editable',
        'no-title': 'No title',

        'onsite.title' :'On-site decommissioning',
        'onsite.text1' :'Moreco has helped organizations varying from the union “Unionen” with on-site decommissioning of workplaces to the training chain “SATS” with on-site decommissioning of their data center.',
        'onsite.text2' :'Project management, planning and execution based on experience, processes and established routines ensure world-class deliveries, which is why we also receive assignments from our colleagues in the industry. We also see ourselves as specialists in organizations with a large geographical spread, such as The Swedish Insurance Agency  and the County Administrative Board, where we performed our on-site services at more than 40 different locations, all between Kiruna, Trelleborg and Gotland.',
        'onsite.list' :'We offer a number of services that can be performed on-site at your premises.',
        'onsite.list-1' :'100% secure data erasure',
        'onsite.list-2' :'Physical destruction of media by shredding',
        'onsite.list-3' :'Unrack servers and other infrastructure',
        'onsite.list-4' :'Removal of cables and racks',
        'onsite.list-5' :'Packing in our safety cabinets',
        'onsite.list-6' :'Disposal and logistics in supervised transports ',
        'onsite.text3' :'At Moreco, we have more than 20 years of experience and expertise in secure IT logistics from data centers. Reversed logistics in the data center require completely different routines for security, project management and, not least, a completely different product knowledge of the infrastructure than normal workplace projects require.',
        'onsite.contact' : 'On your way to the cloud? We\'ll help you, for more information:',

        'placeholder.name' : 'Enter name',
        'purchased' : 'Purchased',
        'rented' : 'Rented',
        'lease' : 'Lease',
        'upload-logo' : 'Upload logo',
        'go-to' : 'Go to',
        'unknown' : 'Unknown',
        'extensions' : 'Extensions',
        'enter-ean' : 'EAN has to be 13 digits',
        'download-csv' : 'Download CSV',
        'displayed-in-tables' : 'Displayed in tables',
        'expired' : 'Expired',
        'undefined' : 'Undefined',
        'clear-filters' : 'Clear filters',
        'file-imported' : 'File imported!',

        'product.info' : 'Product info',
        'product.attributes' : 'Specification',
        'product.info-found' : 'Product info found',
        'product.not-found-icecat' : 'This product could not be found in the product catalog. Fill in this form to create your own product. You need to select one of the available categories in order to registrate your product.',
        'product.supplier' : 'Manufacturer',
        'product.supplier-code' : 'Supplier code',
        'profile.joined' : 'Joined',
        'profile.last-login' : 'Last login',
        'profile.personal-info' : 'Personal info',
        'profile.change-password' : 'Change password',
        'profile.edit' : 'Edit profile',
        'profile.send-reset-link' : 'Send link for new password',
        'profile.delete-account' : 'Delete account',
        'product.icecat-products' : 'Icecat products',
        'product.manual-products' : 'Manual products',

        'product.processor' : 'Processor',
        'product.storage-media' : 'Storage media',
        'product.display-size' : 'Display size',
        'product.hard-drive' : 'Hdd capacity',
        'product.history': 'Product history',
        'product.memory' : 'Memory',

        'product.delete' : 'Delete product',
        'product.delete-sure' : 'Are you sure that you want to delete this product? ',
        'product.market-value' : 'Market value',
        'product.categories' : 'Icecat categories',
        'product.search' : 'Search for product',
        'product.name' : 'Name',
        'product.products-manually' : 'Manually added products',
        'product.products' : 'Products',
        'product.product' : 'Product',
        'product.amount' : 'Amount',
        'product.price-unit' : 'Price/unit',
        'product.monthly-cost' : 'Monthly cost',
        'product.products-handle': 'Handle products',
        'product.EFMV': 'Have an estimated fair market value',
        'product.products-list': 'List products',
        'product.products-fmv-import': 'Import EMV',
        'product.handle-eol' : 'Handle EOL',
        'product.has-eol': 'Categories with EOL',
        'product.with-fmv' : 'With EMV',
        'product.no-fmv' : 'Without EMV',
        'product.last-update' : 'Last update',
        'product.import-title' : 'Import estimated fair market values',
        'product.import-text1' : 'Upload file',
        'product.imported-values' : 'Updated products',
        'product.categories-list' : 'List categories',
        'product.handle-value': 'Resale value',
        'product.handle-resale-value' : 'Handle resale value',
        'product.handle-emv' : 'Handle EMV',
        'product.sync-itglue' : 'Manual ITGlue sync',
        'product.sync-itglue-text' : 'Ecosystem does daily synchronizations with ITGlue. This tool is to manually update all assets with latest information from ITGlue. The synchronization can take up to a couple of minutes.',
        'product.syncing-with-it-glue' : 'Synchronizing',
        'product.synchronize': 'Synchronize',
        'product.create' : 'Create new product',
        'product.list' : 'List products',
        'product.my-products' : 'My products',
        'product.my-products-text' : 'These are your products not created through Icecat. They are only visible within your organization and they need to be assigned a category from Icecat in order for life cycle data to be presented on your asset.',
        'product.my-product' : 'My product',
        'product.edit' : 'Edit product',

        'quote.quote' : 'Quote',
        'quote.quotes' : 'Quotes',
        'quote.create' : 'Create quote',
        'quote.hide' : 'Hide quotes',
        'quote.display' : 'Display quotes',
        'quote.details' : 'Details',
        'quote.list' : 'List quotes',
        'quote.ask-credit' : 'Request credit check',
        'quote.create-contract' : 'Turn into contract',
        'quote.delete' : 'Delete quote',
        'quote.delete-help' : 'Are you sure that you want to delete this quote?',
        'quote.go-back' : 'Go back to quotes',
        'quote.edit' : 'Edit quote',

        'recovery.title' : 'Moreco\'s model for maximizing the financial value of your old IT equipment',
        'recovery.subheading' : 'The circular economy is more than good for our environment, it also benefits your financials as the model contributes to increased demand for used spare parts and accessories.',
        'recovery.list' : 'How we work to increase the value of your old equipment',
        'recovery.list-1' : 'Morecos salesprocess impacts several markets and channels. Not only will this provide the right conditions for optimal reuse, which generates revenue even on older equipment. It will also contribute to a market spread that maintains value even at large volumes. ',
        'recovery.list-2' : 'Our market monitoring keeps track of market prices, supply and demand down to the product number level, which gives us the conditions to optimize the flow of products and components downstream.',
        'recovery.list-3' : 'We have a component strategy, which means we look at market value and demand down to the lowest possible level. In short, this means that three components from a single unit can bring in more value separately than if they are sold together in the unit.',
        'recovery.contact' : 'Want to get a higher value? We\'ll help you!',

        'reseller.reseller' : 'Reseller',
        'reseller.resellers' : 'Resellers',
        'reseller.card' : 'Reseller card',
        'reseller.name' : 'Name',
        'reseller.org-no' : 'Org. no',
        'reseller.comments': 'Comments',
        'reseller.tariff': 'Tariff discount',
        'reseller.now': 'Kickback now',
        'reseller.later': 'Kickback later',
        'reseller.lease' : 'Allow leasing quotes',
        'reseller.create' : 'Create reseller',
        'reseller.edit' : 'Edit reseller',
        'reseller.update' : 'Update reseller',
        'reseller.list' : 'List resellers',
        'reseller.delete' : 'Delete reseller',
        'reseller.delete-sure' : 'Are you sure that you want to delete this reseller? All information like customers, assets and users connected to this reseller will be deleted.',
        'reseller.contact-person': 'Contact person',
        'reseller.go-back': 'Go back to Resellers',


        'reports.excel-full-report' : 'Full report Excel',
        'reports.pdf-full-report' : 'Full report PDF',
        'reports.reports' : 'Reports',
        'reports.lot-report-exists' : 'This lot number already exist in the system. By proceeding the old report will be overwritten.',
        'reports.hard-drives' : 'Hard drives',
        'reports.missing-hdd-audit': 'Missing serial numbers in hard drive audit report',
        'reports.missing-disposition': 'Missing disposition',
        'reports.create-new' : 'Create new report',
        'reports.search' : 'Search',
        'reports.data-security': 'Data security',
        'reports.lot-number': 'Lot number',
        'reports.size' : 'Size MB',
        'reports.erasure-method' : 'Erasure method',
        'reports.status' : 'Status',
        'reports.vendor' : 'Vendor',
        'reports.parent-serial' : 'Parent serial number',
        'reports.start-end-error' : 'The end date has to be later than the start date',
        'reports.recycle1': 'Reduces the amount of waste sent to landfills and incinerators',
        'reports.recycle2': 'Conserves natural resources such as timber, water and minerals',
        'reports.recycle3': 'Increases economic security by tapping a domestic source of materials',
        'reports.recycle4': 'Prevents pollution by reducing the need to collect new raw materials',
        'reports.recycle5': 'Saves energy',
        'reports.recycle-title' : 'Even though there is yet no savings from reuse, the benefit of having us recycle your products are:',
        'reports.delete-text' : 'Are you sure that you want to delete this report? It will no longer be available in the annual report. If you wish to update the report with new information you can just import the new files on the same lot number and the report will be overwritten.',
        'reports.yearly-text-for-admin': 'Select reseller to get an overview over all the customers for the selected reseller.',
        'reports.yearly-text-for-reseller': 'The annual report is a summary of the lot reports during the selected year. Choose between getting an overview of all customers or select a specific customer to get a more detailed report. This report is available to your customers with the same information except of the settlement value. ',
        'reports.cosmetic' : 'Cosmetic',
        'reports.functional' : 'Functional',
        'reports.units' : 'Units',
        'reports.total-charges' : 'Total charges',
        'reports.total-credits' : 'Total credits',
        'reports.total-assets' : 'Total assets ',
        'reports.handled-hdd' : 'Processed hard drives ',
        'reports.finance' : 'Financial specification',
        'reports.read' : 'Read report',
        'reports.deleted-assets': 'Assets deleted from Ecosystem',
        'reports.lots-handled': 'LOTs handled by Moreco ',
        'reports.destruction' : 'Safe hardware destruction',
        'reports.disks-shredded' : 'disks shredded',
        'reports.erasure': 'Data erasure',
        'reports.units-erased': 'units erased with Blancco',
        'reports.erasure-certificate': 'Data erasure certificates',
        'reports.environment': 'Environment',
        'reports.this-is-equal': 'This is equal to the energy use of ',
        'repors.product-specification': 'Product specification',
        'reports.homes': 'homes per year',
        'reports' : 'Reports',
        'reports.generation-time': 'The time for report generation can vary depending on how many of the assets that need to be checked in Blancco\'s database.',
        'reports.delete' : 'Delete Lot report',
        'reports.delete-sure' : 'Are you sure that you want to delete this Lot report? It will no longer be available on the annual report.',
        'reports.audit' : 'Audit reports',
        'reports.from': 'From',
        'reports.create' : 'Generate report',
        'reports.summary' : 'Summary',
        'reports.title' : 'Report creation',
        'reports.enter-lot' : 'Enter LOT number',
        'reports.enter-technician' : 'Enter technician',
        'reports.drop-reports' : 'Upload reports from MRM',
        'reports.annual' : 'Annual report',
        'reports.blancco' : 'Blancco reports',
        'reports.lot': 'LOT reports',
        'reports.to': 'To',
        'reports.no-annual-available' : 'No annual reports available',
        'fetch' : 'Fetch',
        'quick-select' : 'Quick select',
        'lotnumber' : 'LOT #',
        'report.start-date' : 'Start date',
        'report.end-date' : 'End date',
        'reports.finalized' : 'Finalizing date',
        'reports.lot' : 'LOT reports',
        'reports.delete-sure' : 'Are you sure that you want to delete this LOT report? It will no longer be shown in the annual report',
        'reports.delete': 'Delete LOT report',
        'reports.report' : 'Report',
        'reports.title-lot': 'Report for lot number ',
        'reports.model': 'Model',
        'reports.parent-sn': 'Parent S/N',
        'reports.mail': 'Notify users',
        'reports.detail-charges' : 'Detail audit charges',
        'reports.hdd-charges' :' HDD security charges',
        'reports.recycling-charges': 'Recycling charges',
        'reports.other-charges': 'Other services charges',
        'reports.savings-comparison' : 'Savings comparison',
        'reports.equal-homes' : 'Equal to the energy use of {homes} homes per year!',
        'reports.overview' : 'Overview',
        'reports.yearly-text-for-customer' : 'Select year to get annual report.',
        'reports.no-available' : 'No available reports yet.',
        'reports.received' : 'Date received',
        'reports.error-message' : 'The date received must be before the finalizing date',
        'reports.reports-day' : 'Handling time in days',
        'reports.diploma' : 'Diploma',
        'reports.diploma-title' : 'Certificate of environmental savings',
        'reports.diploma-text-1' : ' has through its handling of unused IT electronics contributed to reduced greenhouse gas emissions and thus taken responsibility for the sustainable use of the earth\'s resources.',
        'reports.diploma-text-2' : '\'s contribution to reduced emissions for year {year} amounts to ',
        'reports.comparison-houses' : 'The savings correspond to the annual energy consumption from {houses} households',
        'reports.disk-serial-number': 'Disk serial number',
        'reports.product-serial-number': 'Product serial number',
        'reports.performed-security' : 'Performed security',
        'reports.technician': 'Technician',
        'reports.manufacturer' : 'Manufacturer',

        'charges' : 'Charges',
        'value' : 'Value',
        'categories' : 'Categories',

        'blancco.get-pdf' : 'Search',
        'environmental-savings': 'Environmental savings',
        'LOT #' : 'LOT #',
        'Summary' : 'Summary',
        'category' : 'Category',
        'units' : 'Units',
        'functional' : 'Functional',
        'cosmetic' : 'Cosmetic',
        'disposition' : 'Disposition',
        'settlement-value' : 'Settlement value',
        'buy-price' : 'Buy price',
        'blancco-erased' : 'Erased with Blancco',
        'shredded' : 'Shredded',
        'total' : 'Total',
        'resale' : 'Resale',
        'recycle' : 'Recycle',
        'technician': 'Technician',
        'add-address' : 'Add more emails',
        'send-email' : 'Send email',

        'rightbar.layout-settings' : 'Layout settings',
        'rightbar.layout-width' : 'Layout Width',
        'rightbar.layout-fluid' : 'Fluid',
        'rightbar.layout-boxed' : 'Boxed',
        'rightbar.topbar-theme' : 'Topbar Theme',
        'rightbar.light' : 'Light',
        'rightbar.dark' : 'Dark',
        'rightbar.left-sidebar' : 'Left Sidebar Type',
        'rightbar.default' : 'Default',
        'rightbar.compact' : 'Compact',
        'rightbar.icon' : 'Icon',
        'rightbar.left-sidebar-theme' : 'Left Sidebar Theme',



        'preparing' : 'Preparing',
        'show-entries' : 'Show entries',
        'previous' : 'Previous',
        'next' : 'Next',
        'showing' : 'Showing',
        'to': 'to',
        'of' : 'of',
        'entries' : 'entries',
        'sek' : 'SEK',
        'select' : 'Select',
        'show' : 'Show',
        'NA' : 'Not available at the moment',
        'covered' : 'Covered',
        'outside' : 'Outside',
        'calculating' : 'Calculating',
        'in-system' : 'In the system',
        'all-categories' : 'All categories',
        'copied' : 'Copied!',
        'Status' : 'Status',

        'signed': 'Signed',
        'extension' : 'Extended',
        'pending': 'Pending',
        'surcharge' : 'Surcharge',
        'cancellation' : 'Cancellation',
        'buy-out-request' : 'Buy out request',
        'pagination.previous' : 'Previous',
        'pagination.next' : 'Next',

        'call-us' : 'Call us',
        'mail-us' : 'Mail us',
        'select-contract' : 'Select contract',
        'not-found' : 'does not exist',

        'no-statuses' : 'You have to add at least one status in order to assign this asset a status.',
        'select-customer' : 'Select Customer first',
        'select-reseller' : 'Select Reseller first',
        'status.add-new' : 'Add new',
        'status.delete' : 'Delete status',
        'status.delete-sure' : 'Are you sure you want to delete this status? All assets with this status will loose their status.',
        'status.update' : 'Update status',
        'status.update-sure' : 'Are you sure that you want to update the status? All assets with this status id will get the updated status.',

        'supplier.supplier': 'Supplier',
        'supplier.suppliers': 'Suppliers',
        'supplier.name' : 'Name',
        'supplier.updated' : 'Last updated',
        'supplier.create' : 'Add new supplier',
        'supplier.edit' : 'Edit supplier',
        'supplier.delete' : 'Delete supplier',
        'supplier.delete-sure' : 'Are you sure you want to delete this supplier? The supplier will not be available when creating new contracts',

        'start.title' : 'Get started!',
        'start.step': 'Step ',
        'start.step-customer' : 'Create a customer',
        'start.step-customer-assets-1' : 'Once you have created your customer, you can start adding assets to it. They can either be linked to a contract or not. The customer\'s assets will be categorized according to their warranty data and you will have the ability to monitor the asset\'s life cycle and see the potential business opportunities.',
        'start.step-customer-assets-2' : 'Your customer will share the same asset view as you. The only difference is that they do not have access to the estimated market value and your statuses. They can create their own statuses that will be visible to you, however, you can not edit these statuses.',
        'start.step-contract' : 'Create a contract',
        'start.step-customer-contracts' : 'You can create contracts with your customers and link assets to them. They are listed on the customer card and under contract in the side menu. When a contract is archived, all assets linked to the contract are deleted. The customer shares the same view as you but can not edit the contract.',
        'start.step-asset' : 'Create Assets',
        'asset.use-API' : 'Use our API',
        'asset.create-with-API' : 'You need a token in order to use our API. You can get that on your profile page. ',
        'start.step-status' : 'Create asset statuses',
        'start.step-user' : 'Create users and give them customized permissions',
        'start.step-user-text': 'You can create users for your customer so that they can also log in and use Ecosystem. These users can have two different types of accounts, Enduser Basic and Enduser Admin. What distinguishes them is the default permissions, however, you can change these exactly as you want.', 
        'start.lifecycle' : 'Your lifecycle data',
        'start.dont-show' : 'Dont show this guide on login again',

        'start.step-contract-text' : 'You can create contracts with your customers in the system and connect assets to them. The contract can be activated, inactivated and archived. All changes to the contract are logged in a history and you can extend the contract and see the extension history too. ',
        'start.step-contract-extensions' : 'You can extend your contract. The extension will then be listed on the contract and the end date of the contract will be updated to the new selected date.',
        'start.step-contract-substarts' : 'The sub-starts are created from the framework agreement and will be listed on it. All assets linked to sub-species will also be listed on their main agreement. The substarts otherwise have the same functionality as a regular contract and can be updated, extended and archived.',
        'start.step-contract-history' : 'Each contract has a history listed on the contract. For example, updates are logged there and who made them.',

        'start.step-contract-assets' : 'To link assets to a contract, you can import them from the contract or enter the contract ID when importing assets with CSV file. You can then choose to move assets between contracts or remove them. You can also delete them completely. When an asset is linked to a contract, it is of the type rented. If you choose to disconnect an asset from the contract, it will still be of type rented until you change it.',
        'start.step-contract-archive' : 'When you choose to archive a contract, all assets associated with it are deleted.',
        'start.step-asset-text' : 'You can create new assets in 3 different ways if you have the permission to create assets in the system. Every asset is unique by its serial number and its used to fetch information about your asset.',
        'start.step-assets-manually-text' : 'If you do not have all the necessary information ready in a CSV file, you can add your assets manually. What you need is the serial number, the supplier\'s name and product code or you can search for your product in the product catalog.',
        
        'start.step-asset-user' : 'You can select if your asset is in use and display by who it is used.',
        'start.step-asset-warranty' :'When you register a new serial number, we will perform a search for you to retrieve warranty data for your product. If this is found, it will be presented on your asset. If the search is performed and warranty data cannot be found, you can choose to enter it manually if you have it available.',
        'start.step-asset-eol' :'Each product that has a product category assigned will have an EOL date. EOL stands for End Of Life and is a value for the estimated lifespan of your product. If your asset is older than this date, it will be highlighted to alert you that it may be time to replace it.',
        'start.step-asset-co2' :'When IT products are reused instead of being discarded, we help the environment by reducing CO2e emissions. If your asset\'s product category has available data on its CO2e impact, it will be displayed on your asset and a summary will be presented on your dashboard.',
        'start.step-asset-delivery' :'The delivery date is used to perform various calculations for your asset so make sure to always have it filled out.',
        'start.step-asset-product' :'When you want to create a new asset, a search is made for available product information. The search can be done either with EAN code or with the supplier and the supplier\'s product code. If no product information is available, it is possible to create a product manually. In order for these products to also be able to receive life cycle data, it is required that a product category is selected. You can then list and manage your manual products as you wish.',

        'start.step-customer-text' : 'You can create new users on the create customer page. Each customer will get a ID that will be used to attach assets and contracts to them. All information regarding the customer can be found on their customer card.',

        'start.step-status-text-customer' : 'Add your own statuses to keep track of your assets.',
        'start.step-status-text-reseller' : 'One asset can have 2 different statuses in the system at the same time. One is the customer status that you can see and filter the assets with, and one is your internal status that you can add or remove from the asset.',
        'start.step-status-text-admin' : 'One asset can have 3 different statuses in the system at the same time. One is the customer status that you can see and filter the assets with, the other one is the Reseller status that you can read and filter the assets with and the last one is the Global status that you can add or remove from the asset.',

        'start.step-user-text-customer' : 'You can create new users within your organization if you have the permission to create new users. Every role have some predefined permissions that can be customized for the specific user that you want to create.',
        'start.step-user-text-reseller' : 'You can add more users within your organization and for you customers if you have the permission to create new users. Every role have some predefined permissions that can be customized for the specific user that you want to create.',
        'start.step-user-text-admin' : 'You can add more users within your organization and for you resellers and customers. Every role have some predefined permissions that can be customized for the specific user that you want to create.',

        'start.step-reseller' : 'Create a reseller',
        'start.step-reseller-text' : 'You can create resellers on the create reseller page. Every reseller will get their own reseller ID that can be used to connect customers and contracts to them. All information regarding the reseller can be found on their reseller card.',

        'start.step-kam' : 'Create Key Account Managers',
        'start.step-kam-fetch' : 'Fetch Key Account Managers',
        'start.step-kam-text' : 'You can choose which customers and what information every user should have access to within your organization by making them a Key Account Manager. ',
        'start.step-kam-text-2' : 'Key account manager is not a separate role but more like a filter for what information is displayed for that specific user. A KAM with assigned customers will only have access to the information associated with the selected customers, but its authority in the system will still be governed by the permissions assigned to that user. For example, one Key account manager may have the permission to create new customers while another does not.',


        'support-agreement.support-agreement' :'Support agreement ',
        'support-agreement.support-agreements' :'Support agreements',
        'support-agreement.list' :'List agreements',
        'support-agreement.search' :'Search for agreements',
        'support-agreement.archive' :'Archive',
        'support-agreement.archive-yes' :'Yes, archive',
        'support-agreement.archive-info' :'This agreement is archived',
        'support-agreement.to-archive' :'Archive agreement',
        'support-agreement.to-archive-text' :'Are you sure that you want to archive this agreement?',
        'support-agreement.archived-support-agreements' :'Archived agreements',
        'support-agreement.add' :'Add agreement',
        'support-agreement.delete' :'Delete agreement ',
        'support-agreement.delete-info' :'Are you sure that you want to delete agreement # ',
        'support-agreement.delete-yes' :'Yes, I want to delete it.',
        'support-agreement.delete-no' :'No, go back to agreement',
        'support-agreement.edit' :'Edit agreement',
        'support-agreement.contract_no' :'Agreement number',
        'support-agreement.start_date' :'Start date',
        'support-agreement.end_date' :'End date',
        'support-agreement.cost' :'Cost',
        'support-agreement.termination' :'Termination rules',
        'support-agreement.conditions' :'Conditions',
        'support-agreement.no-agreements' :'No agreements to display',
        'support-agreement.assets' :'Assets connected to this agreement',
        'support-agreement.search-title' :'Search for and filter agreements',
        'support-agreement.search-info' :'Only results where all the criteria match will be displayed. A search from the free text field will include agreement number. You can then filter the results further by using the regular search field.',
        'support-agreement.free' :'Search by Asset, on serial number, product number, supplier or category',
        'support-agreement.title' :'Title',
        'support-agreement.upload-assets' :'Handle assets',
        'support-agreement.select-serials' :'Select serial numbers',
        'support-agreement.select-assets-desc' :'Insert serial numbers for the assets that you want to connect, use word-rapping to seperate them',
        'support-agreement.remove-assets' :'Remove assets',
        'support-agreement.add-assets' :'Connect assets',
        'support-agreement.add-asset-info' :'Select the assets you want to connect to this support agreement',
        'support-agreement.remove-asset-info' :'Select the assets you want to remove from this support agreement',
        'support-agreement.confirm-remove' :'Do you want to remove these assets from the agreement?',
        'support-agreement.back-to' :'Go back to agreement',

        'api-token-info' : 'Note that you can\'t view a key again after it has been generated, so store your keys as passwords somewhere else safe. API key does not expire, but it can be revoked anytime.',

        'products.new-this.month' : 'New manual products this month',

        'table.show-results' : 'Showing { from } to { to } of { size } Results',

        'user.notify-user': 'Notify user',
        'user.notify-on': 'User gets email notifications about new reports',
        'user.notify-off': 'Notify user about new reports in the system',

        'user.revoke-key' : 'Revoke API key',
        'user.no-user-for-kam' : 'No avavilable users to list',
        'user.total' : 'Total in system',
        'user.new-this-month' : 'New users this month',
        'user.last-login' : 'Last login',
        'user.users' : 'Users',
        'user.display' : 'Display users',
        'user.hide' : 'Hide users',
        'user.create' : 'Create user',
        'user.user' : 'User',
        'user.role' : 'Role',
        'user.edit' : 'Edit user',
        'user.permissions' : 'Permissions in the system',
        'user.permission-read' : 'Read',
        'user.permission-update' : 'Update',
        'user.permission-create' : 'Create',
        'user.permission-delete' : 'Delete',
        'user.handle' : 'Handle',
        'user.delete' : 'Delete user',
        'user.delete-sure' : 'Are you sure that you want to delete this account? The deletion is permanent and all data regarding this user will get lost.',
        'user.name' : 'Name',
        'user.account' : 'Account',
        'user.list' : 'List users',
        'user.user-back' : 'Back to user',
        'user.permissions-info' : 'You can customize this users permissions by activation or deactivation.',
        'user.change-password' : 'Change password',
        'user.edit-profile': 'Edit profile',
        'user.account-security' : 'Account security',
        'user.enable-2FA' : 'Enable 2FA',
        'user.disable-2FA' : 'Disable 2FA',
        'user.2fa-login-text' : 'Please confirm your access to your account by entering the authentication code provided by your authenticator application',
        'user.setup-2FA': 'Setup two factor authentication',
        'user.setup-2FA-step1-title' : 'Install the verification app',
        'user.setup-2FA-step1-text' : 'Download and install your selected authenticator app on your device.',
        'user.setup-2FA-step2-title' : 'Scan the qr code',
        'user.setup-2FA-step2-text' : 'Open the authenticator app and use the camera to scan the qr code.',
        'user.setup-2FA-step3-title' : 'Enter passcode',
        'user.setup-2FA-step3-text' : 'Enter the 6 digit passcode on your device to Enable 2FA.',
        'user.disable-2FA-text' : 'The two-step-verification offers a higher security level and makes it more difficult for someone to log in with your account. If you disable the 2FA you will just need your password to log in.',
        'user.keep-enabled' : 'Keep 2FA activated',
        'user.profile' : 'Profile',
        'user.old-code' : 'The code is not valid, please try again with a new.',
        'user.api-key': 'API key',
        'user.api-key-text' : 'Used to access the Ecosystem API.',
        'user.get-key' : 'Get key',
        'user.upload-profile-picture' : 'Upload profile picture',
        'user.handle-kam' : 'Handle key account manager',
        'user.create-kam' : 'Create key account manager',
        'user.list-kam' : 'List KAMs',
        'user.update-kam' : 'Update key account manager',
        'user.no-selected-customers' : 'Note! This user will get access to all customers!',
        'user.no-selected-customers-text' : 'If you continue this user will no longer have the role key account manager and will be able to see all the customers in the system.',
        'user.yes-save-without-selected-customers' : 'Yes this is correct. I wish to give this user access to all customers.',
        'user.delete-kam' : 'Remove key account manager role',
        'user.key-account-managers' : 'Key account managers',
        'user.api' : 'Ecosystem API',
        'user.2fa-mobile' : 'This feature can only be activated from desktop at the moment',

        'warranty' : 'Warranty',
        'warranty.expired' : 'Expired',
        'warranty.due-soon' : 'Due soon',
        'warranty.unknown' : 'Unknown',
        'warranty.hardware-replacement-title' : 'Hardware replacement opportunities',
        'warranty.purchased-assets' : 'Purchased assets',
        'warranty.rented-assets' : 'Rented assets',
        'warranty.total-value' : 'Total value',
        'warranty.based-on' : 'This value is based on the price of ',
        'warranty.price-missing' : 'Price is missing on',
        'warranty.based-on-warranty' : 'assets with expired warranty or warranty that will expire in six months',
        'warranty.co2e-text' : 'The total C02e emissions for assets without warranty or with warranty that will expire soon is',
        'warranty.assets-covered' : 'All of your assets in this category are covered by warranty',
        'warranty.calculating' : 'Compiling warranty data',
        'warranty.calculating-text' : 'This can take a couple of seconds with many assets',

        'KAM' : 'KAM',
        'Assets' : 'Assets',
        'icecat-products' : 'Icecat',
        'manual-products' : 'Manual',

        'yes' : 'Yes',
        'view' : 'View',
        'change' : 'Change',
        'help' : 'Help',
        'upload-file' : 'Upload file',
        'create-new' : 'Create new',
        'list' : 'List',
        'show-spec' : 'Show specification of fields',
        'show-product' : 'Show product info',
        'login-other-account' : 'Login with another account',

        'docs' : 'Documentation',
        'docs.about-asset' : 'About your assets',
        'docs.about-asset-text' : 'The available information and where you can find it.',
        'docs.create-asset' : 'Create new assets',
        'docs.create-asset-text' : 'How you can upload and create new assets.',
        'docs.update-asset' : 'Edit your asset information',
        'docs.update-asset-text' : 'What information can be updated and how?',
        'docs.delete-asset' : 'Delete your asset',
        'docs.delete-asset-text' : 'What happens and how?',

        'create_asset' : 'Create Asset',
        'read_asset' : 'Read Asset',
        'update_asset' : 'Update Asset',
        'delete_asset' : 'Delete asset',

        'create_support_agreement' : 'Create support agreement',
        'read_support_agreement' : 'Read support agreement',
        'update_support_agreement' : 'Update support agreement',
        'delete_support_agreement' : 'Delete support agreement',

        'create_contract' : 'Create Contract',
        'read_contract' : 'Read Contract',
        'update_contract' : 'Update Contract',
        'delete_contract' : 'Delete Contract',

        'create_quote' : 'Create Quote',
        'read_quote' : 'Read Quote',
        'update_quote' : 'Update Quote',
        'delete_quote' : 'Delete Quote',

        'create_reseller' : 'Create Reseller',
        'read_reseller' : 'Read Reseller',
        'update_reseller' : 'Update Reseller',
        'delete_reseller' : 'Delete Reseller',

        'create_customer' : 'Create Customer',
        'read_customer' : 'Read Customer',
        'update_customer' : 'Update Customer',
        'delete_customer' : 'Delete Customer',

        'create_user' : 'Create User',
        'read_user' : 'Read User',
        'update_user' : 'Update User',
        'delete_user' : 'Delete User',    

        'warrantyEndsInside' : 'More than 6 months',
        'warrantyEndsOutside' : 'Outside warranty',
        'warrantyEndNotAvailable' : 'Not available',
        'warrantyEnds3Months' : 'Less than 3 months',
        'warrantyEnds6Months' : 'Less than 6 months',

        'threeMonths' : '3 Months',
        'sixMonths' : '6 Months',
        'inside' : 'Inside',
        'unkown' : 'Unknown',
    }
}

export default trans