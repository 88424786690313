import React, { useState } from "react";
import { useParams } from "react-router-dom";
// Redux and actions
import { useSelector, useDispatch } from "react-redux";
import { deleteAssets, inventoryAssets, clearState } from 'store/asset/actions'
import { removeAssetsFromContract, connectAssetsToContract } from 'store/contract/actions'
// Components
import { Col  } from 'reactstrap';
import { AssetTablesActions, AssetTableTitle } from './index';
import AssetListModal from '../AssetListModal';
import { RemotePaginationTable } from "Components/Lists";
// Functions
import { getAssetColumns } from 'helpers/helpers';
import fileDownload from 'js-file-download';

import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();
var token = cookies.get("token");

/* This component is used on all pages with assets */

const AssetsTable = ({
  title, // Display Title on warranty pages eg.
  textColor, // Textcolor on custom title.
  columns, // The type of columns that should be used.
  predefinedFilter, // For the page the table is displayed
  onAddToContract, // For Button actions
  onRemoveFromContract, // For Button actions
  onMoveToContract, // For Button actions
  contracts, // For handling assets on contract
  updateContractInfo, // To rerender Contract after changes
  match
}) => {
  const dispatch = useDispatch();

  const [contract_id, setContractId] = useState(null);
  const [type, setType] = useState(null);
  const [download, setDownload] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [modal, setModal] = useState(false);
  const [filter, setFilter] = useState({});

  const error = useSelector((state) => state.Contracts.error);
  const success = useSelector((state) => state.Contracts.success);
  const pagination = useSelector((state) => state.Model.assets);
  const contractSuccess = useSelector((state) => state.Contracts.success);
  const errors = useSelector((state) => state.Assets.errors);
  const assetSuccess = useSelector((state) => state.Assets.success);
  const me = useSelector((state) => state.Global.me);

  const { id } = useParams();


  const togglemodal = (type, modal) => {
    dispatch(clearState());
    setType(type);
    setModal(modal);
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      const ids = [...selectedIDs, row.id];
      setSelectedIDs(ids);
    } else {
      const ids = selectedIDs.filter((x) => x !== row.id);
      setSelectedIDs(ids);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) setSelectedIDs(ids);
    else setSelectedIDs([]);
  };

  const deattachAssetsFromContract = () => {
    dispatch(removeAssetsFromContract(id, selectedIDs))
    .then(() => {
      setSelectedIDs([])
      updateContractInfo()
    });
  };

  const attachAssetsToContract = () => {
    dispatch(connectAssetsToContract(id, selectedIDs))
    .then(() => {
      setSelectedIDs([])
      updateContractInfo()
    });
  };

  const moveAssetsToOtherContract = () => {
    if (contract_id) {
      dispatch(connectAssetsToContract(contract_id, selectedIDs))
      .then(() => {
        setSelectedIDs([])
        updateContractInfo()
      })
    }
  };

  const deleteSelectedAssets = () => {
    dispatch(deleteAssets(selectedIDs))
    .then(() => {
      setSelectedIDs([])
      window.location.reload()
    });
  };

  const inventory = () => {
    dispatch(inventoryAssets(selectedIDs));
    setSelectedIDs([])
    window.location.reload()
  };

  const downloadCSV = () => {
    setDownload(true);
    let filters = (predefinedFilter ?? filter)
    axios.post(
      `${process.env.REACT_APP_API_URL}/assets/export`, { assets: selectedIDs, filters: filters },
        { headers: {"Content-Type": "application/json", Authorization: `Bearer ${token}`}}
      ).then((res) => {
        setDownload(false);
        fileDownload(res.data, 'assets.csv');
      });
  };

  const getFilter = filter => {
    setFilter(filter)
  }


  return (
    <>
      <Col>
        {title ? <AssetTableTitle textColor={textColor} title={title} /> : null}
        <RemotePaginationTable
          model={'assets'}
          pagination={pagination}
          predefinedFilter={predefinedFilter}
          getFilter={getFilter}
          columns={getAssetColumns(me.type, (filter.type === 'rented' ? 'rented' : columns ?? 'mixed'))}
          pageUrl={`${process.env.REACT_APP_API_URL}/assets`}
          path={match?.path}
          selectRow={{
            mode: "checkbox",
            clickToSelect: true,
            selectedIDs: selectedIDs,
            style: { background: "rgba(17, 130, 174, 0.25)" },
            onSelect: handleOnSelect,
            onSelectAll: handleOnSelectAll,
          }}
          can={'Asset'}
        >
        <AssetTablesActions
          onAddToContract={onAddToContract}
          onRemoveFromContract={onRemoveFromContract}
          onMoveToContract={onMoveToContract}
          downloadCSV={downloadCSV}
          contract_id={contract_id}
          modal={modal}
          selectedIDs={selectedIDs}
          togglemodal={togglemodal}
          download={download}
        />
        </RemotePaginationTable>
      </Col>
      <AssetListModal
        selectedIDs={selectedIDs}
        modal={modal}
        contracts={contracts}
        setContractId={setContractId}
        togglemodal={togglemodal}
        display={type}
        removeAssets={deattachAssetsFromContract}
        deleteSelectedAssets={deleteSelectedAssets}
        inventory={inventory}
        addAssets={attachAssetsToContract}
        moveAssets={moveAssetsToOtherContract}
        success={success || contractSuccess || assetSuccess}
        error={error}
        errors={errors}
      />
    </>
  );
};

export default AssetsTable