import React from "react";

// Components
import { Button } from "reactstrap";
import TextInputField from "Components/Form/TextInputField";
import { AvForm } from "availity-reactstrap-validation";

// Functions
import { translate } from 'helpers/helpers';
import { PASSWORD_REGEXP } from "Constants";

const ChangePassword = ({ changePassword }) => (

        <AvForm onValidSubmit={changePassword}>
            <TextInputField
              name="old_password"
              id="old_password"
              value=""
              placeholder={"form.enter-old-password"}
              errorMessage={"form.enter-old-password"}
              type="password"
              required={true}
              label={"form.old-password"}
              min={6}
            />
            <TextInputField
              name="new_password"
              id="new_password"
              value=""
              placeholder={"form.enter-new-password"}
              errorMessage={"form.password"}
              type="password"
              required={true}
              label={"form.new-password"}
              min={10}
              validate={{ pattern: { value: PASSWORD_REGEXP } }}
            />
            <Button type="submit" className="" color="success">
              {translate("user.change-password")}
            </Button>
        </AvForm>
    )

export default ChangePassword
